import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentDetails } from './payment-details';
import { GiveNowService } from '../_services/give-now.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.css']
})
export class PayNowComponent implements OnInit {

  paymentForm: FormGroup;
  submitted: boolean = false;
  years: any = [];
  months: any = [];
  currentMonth: any = [];
  currentYear: any = [];
  thisMonth: number
  thisYear: number
  rangeValue: number
  calculatedTipAmount: number = 0;
  donationAmount: number;
  paymentDetails: PaymentDetails;
  fatchedParams: any;
  organizationName: string;
  sourceId: string = ""
  ParamsArray: string[] = []
  QRData: any;
  QR_info_data: any;
  orgLogo: string;
  redFlag: boolean = false;
  campaignName: string = "";
  isCampaign: boolean = false;

  emailId: any;
  actionId: any;
  qrcode_id: any;

  constructor(private giveNowService: GiveNowService, private activatedRoute: ActivatedRoute, private route: Router,private spinnerService: NgxSpinnerService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.fatchedParams = params

      this.emailId = params['emailid'];
      this.actionId = params['actionid'];
    })

  }

  ngOnInit() {
        
    var year = new Date().getFullYear();
    var currentDate = new Date()
    var range = [];
    var range1 = [];
    var range2 = [];
    var range3 = [];
    for (var i = 0; i < 10; i++) {
      range.push({
        label: year + i,
        value: year + i
      });
    }
    this.years = range;
    this.thisYear = currentDate.getFullYear()
    this.thisMonth = currentDate.getMonth()
    for (var i = 1; i <= 12; i++) {
      range1.push({
        label: i,
        value: i
      });
    }
    this.months = range1;

    if (this.thisYear == this.years[0].value && this.thisMonth == (this.months[this.thisMonth - 1].value)) {
      for (var i = this.thisMonth; i <= 12; i++) {
        range2.push({
          label: i,
          value: i
        });
      }
    }
    this.currentMonth = range2

    if (this.thisYear == this.years[0].value && this.thisMonth == (this.months[this.thisMonth - 1].value)) {
      for (var i = 0; i < 10; i++) {
        range3.push({
          label: year + 1 + i,
          value: year + 1 + i
        });
      }
    }
    this.currentYear = range3



    this.paymentForm = new FormGroup({
      account_id: new FormControl(""),
      transaction_type: new FormControl(),
      transaction_type_id: new FormControl(""),
      card: new FormGroup({
        email: new FormControl(""),
        name_on_card: new FormControl("", Validators.required),
        card_number: new FormControl("", [Validators.required, Validators.minLength(16), Validators.maxLength(16),]),
        expiration_month: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*"), Validators.min(1), Validators.max(12),]),
        expiration_year: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*"), Validators.maxLength(4),]),
        cvv: new FormControl("", [Validators.required, Validators.pattern("^[0-9]*"), Validators.minLength(3), Validators.maxLength(3),]),
        zip: new FormControl("", [Validators.pattern("^\\d{5}(-{0,1}\\d{4})?$"), Validators.minLength(5), Validators.maxLength(11)]),
      }),
      amount: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]{0,3}\.[0-9]{0,2}$"), Validators.max(999.99), Validators.minLength(1), Validators.maxLength(6)]),
      tip_amount: new FormControl(15),
      no_share_giving: new FormControl(false)
    });
    this.checkAPI();

  }


  get f() {
    return this.paymentForm['controls']['card']['controls']
  }
  get a() {
    return this.paymentForm['controls']
  }

  setTip(data: any) {
    this.paymentForm.patchValue({
      tip_amount: Number(data.value)
    })
  }

  calculateTip() {
    let donationAmount = this.paymentForm.value.amount
    this.donationAmount = donationAmount

    let tipAmount = this.paymentForm.value.tip_amount

    this.calculatedTipAmount = donationAmount * ((tipAmount) / 100)
    return (this.calculatedTipAmount)
  }

  calculateTotalAmtForTip() {
    let total = Number(this.paymentForm.value.amount) + Number(this.calculatedTipAmount)
    return total
  }
  
  calculateTotalAmt() {
    let total = Number(this.paymentForm.value.amount)
    return total
  }

  onSubmit() {
    this.submitted = true;
    this.spinnerService.show();
    this.paymentForm.patchValue({
      amount: Number(this.paymentForm.value.amount),
      tip_amount: Number(this.calculatedTipAmount?.toFixed(2))
    })
    Number(this.paymentForm.value.amount);
    this.giveNowService.postPaymentDetails(this.paymentForm.value).subscribe(res => {
      if (res.status == 'fail') {
        this.route.navigate(['payfailed']);
      } else {
        if(this.emailId && this.actionId){
          this.updateActionTracking(this.emailId, this.actionId, this.paymentForm.value);
        }
        this.route.navigate(['paysuccess']);
      }
      this.spinnerService.hide();  
    },
      error => {
        this.route.navigate(['payfailed']);
        this.spinnerService.hide();  

      });
      this.paymentForm.patchValue({
        tip_amount: Number(15)
      })
  }

  checkAPI() {
    if (this.fatchedParams) {
      let textData = this.fatchedParams.data;
      let ArrayOfParams = textData.split(":");
      this.ParamsArray.push(ArrayOfParams);
      this.getQrCodeUrlData(this.ParamsArray[0][2]);
      this.setAccountId(this.ParamsArray[0][1]);
      this.setTransactionType(this.ParamsArray[0][0]);
      this.setTansactionTypeId(this.ParamsArray[0][2]);
    }
  }

  setAccountId(data) {
    this.paymentForm.patchValue({
      account_id: data
    })
  }
  setTransactionType(data) {
    this.paymentForm.patchValue({
      transaction_type: Number(data)
    })
  }

  setTansactionTypeId(data) {
    this.paymentForm.patchValue({
      transaction_type_id: data
    })
  }

  getQrCodeUrlData(sourceId: string) {
    this.spinnerService.show();
    this.giveNowService.getQRCodeURLInfo(sourceId).subscribe(res => {
      this.QRData = res;
      this.QR_info_data = JSON.parse(this.QRData?.qr_info_data);
      this.orgNameAndLogo();
      if (this.QR_info_data?.intake_rules?.require_email) {
        this.paymentForm.get('card').get('email').setValidators([Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')])
        this.paymentForm.get('card').get('email').updateValueAndValidity();
      }
      if (this.QR_info_data?.campaign != null) {
        if (this.QR_info_data?.owner == this.QR_info_data?.campaign) {
          this.isCampaign = false;
        } else {
          this.isCampaign = true;
          this.campaignName = this.QR_info_data?.campaign;
        }
      } else {
        this.isCampaign = false;
      }
      this.spinnerService.hide();  
    })
  }

  orgNameAndLogo() {
    this.organizationName = this.QR_info_data?.owner
    this.orgLogo = this.QR_info_data?.small_logo_path
  }

  expiryBugMonth(data) {
    let selectedValue = data?.selectedIndex
    if (Number(data[selectedValue].text) <= this.currentMonth[0].value) {
      this.redFlag = true
    }
    if (Number(data[selectedValue].text) > this.currentMonth[0].value) {
      this.redFlag = false
    }
  }

  updateActionTracking(emailId: string, actionId: string, postData: any){
    // const totalAmountWithTip = this.calculateTotalAmtForTip();
    // const totalAmountWithFees = this.calculateTotalAmt();
    const donationAmount = postData.amount
    const payload = {
      account_id: "",
      email_id: emailId,
      action_id: actionId,
      action_tracking: {
        action_id: actionId,
        action_name: "Give Now",
        action_page: true,
        action_amount: donationAmount,
        action_outcome:"",
        campaign_id: this.qrcode_id
      }
    };

    // if (this.QR_info_data?.intake_rules?.require_tips) {
    //   payload.action_tracking.action_amount = totalAmountWithTip
    // } else {
    //   payload.action_tracking.action_amount = totalAmountWithFees
    // }

    // Call your API service to update the API
    this.giveNowService.getActionTracking(emailId, payload).subscribe(
      (res: any) => {
        // console.log('API updated successfully:', res);
      },
      (error: any) => {
        console.error('Error updating API:', error);
      }
    );
  }

}



