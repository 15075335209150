import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { PaymentDetails } from '../pay-now/payment-details';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GiveNowService {

  // baseUrl = environment.giveNow;
  //qrCodeUrl = environment.qrCode;
  baseUrlGive = environment.baseUrlGive;

  constructor(private http: HttpClient) { }

  public postPaymentDetails(data: PaymentDetails): Observable<any> {
    const body = data
    return this.http.post(this.baseUrlGive + "give/onetime", body)
  }

  public getQRCodeURLInfo(dataSourceId: string): Observable<any> {
    return this.http.get(this.baseUrlGive + "qrcode/" + dataSourceId + "/info").pipe(map(res => res));
  }

  public getStripeData(data: any): Observable<any> {
    return this.http.post(this.baseUrlGive + "give/onetime/intent", data).pipe(map(res => res));
  }
  public getTrackingData(campaignid: string): Observable<any> {
    return this.http.get(this.baseUrlGive + `campaign/${campaignid}/trackingcodes`).pipe(map(res => res));
  }

  public getStoreData(orderid: any): Observable<any> {
    return this.http.get(this.baseUrlGive + `store/order/${orderid}/info`).pipe(map(res => res));
  }
  getWallet(accountid: string) {
    let request: any = {};
    request.account_id = accountid;
    request.get_wallet_content = {
      get_charge_cards: true,
      get_bank_accounts: true
    }
    const body = request;
    return this.http.post(this.baseUrlGive + `${accountid}/wallet`, body).pipe(map(res => res));
  }
  public getStoreOrderDetails(orderid: any): Observable<any> {
    return this.http.get(`${this.baseUrlGive}store/order/${orderid}`).pipe(map(res => res));
    //return this.http.get(this.baseUrlGive + `store/order/${orderid}/info`).pipe(map(res => res));
  }

  public getEventTicketOrderData(orderid: any): Observable<any> {
    return this.http.get(this.baseUrlGive + `ticket/event/order/${orderid}`).pipe(map(res => res));
  }

  public getRaffleTicketOrderData(organizationId: any, orderid: any): Observable<any> {
    return this.http.get(this.baseUrlGive + `organization/${organizationId}/raffles/orders/${orderid}`).pipe(map(res => res));
  }

  public getopenauctionOrderData(organizationId: any, orderid: any, itemId: any): Observable<any> {
    return this.http.get(this.baseUrlGive + `organization/${organizationId}/auctions/${orderid}/items/${itemId}/bids`).pipe(map(res => res));
  }

  public getActionTracking(emailid: any, actionData: any): Observable<any> {
    const body = actionData;
    return this.http.put(this.baseUrlGive + `organization/tracking/email/${emailid}/status`, body).pipe(map(res => res));
  }

  public GetPledgeDonationinfo(organizationId: any, contactid: any, pledgeid: any) {
    // return ApiPath.getBaseUrl() + `organization/${organizationId}/crm/contacts/${contactid}/pledges/${pledgeid}/info`;
    return this.http.get(this.baseUrlGive + `organization/${organizationId}/crm/contacts/${contactid}/pledges/${pledgeid}/info`).pipe(map(res => res));
  }

  public getPayerCoverAmount(organizationId: any) {
    return this.http.get(this.baseUrlGive + `organization/${organizationId}/payercoverfees`).pipe(map(res => res));
  }

}
