import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GiveNowService } from '../../_services/give-now.service';
import { loadStripe, Stripe, StripeElements, StripeCardNumberElementOptions, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement, StripeCardExpiryElementOptions, StripeCardCvcElementOptions, ConfirmCardSetupData } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import { ToastMessage } from '../../common/commonfunction';
import { timer } from 'rxjs';

@Component({
  selector: 'app-pay-now-stripe',
  templateUrl: './pay-now-stripe.component.html',
  styleUrls: ['./pay-now-stripe.component.css']
})
export class PayNowStripeComponent implements OnInit {
  paymentForm: FormGroup;
  submitted: boolean = false;
  years: any = [];
  months: any = [];
  currentMonth: any = [];
  currentYear: any = [];
  thisMonth: number
  thisYear: number
  rangeValue: number
  calculatedTipAmount: number = 0;
  donationAmount: number;
  fatchedParams: any;
  organizationName: string;
  sourceId: string = ""
  ParamsArray: string[] = []
  QRData: any;
  QR_info_data: any;
  orgLogo: string;
  redFlag: boolean = false;
  campaignName: string = "";
  isCampaign: boolean = false;
  private stripe: Stripe;
  stripeSetupData: any;
  private elements: StripeElements;
  private cardNumber: StripeCardNumberElement;
  private cardExpiry: StripeCardExpiryElement;
  private cardCvc: StripeCardCvcElement;
  stripeCardNumberErrorMessage;
  stripeExpiryErrorMessage;
  stripeCVCErrorMessage;
  sponsorName: string;
  accountId: string;
  paymentSourceData;
  cardValidatorsData: any;
  orgid: any;
  whitelabel: any;
  isTrackingCode: boolean = false;
  trackingDetails: any;
  arraytracking: any = []
  emailId: any;
  actionId: any;
  qrcode_id: any;
  constructor(private giveNowService: GiveNowService, private activatedRoute: ActivatedRoute, private route: Router, private spinnerService: NgxSpinnerService, private toastMessage: ToastMessage) {

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.fatchedParams = params
      this.orgid = params['organization'];
      //if (params?.sponsor) {
      //  this.sponsorName = params?.sponsor;
      //}
      this.whitelabel = params['whitelabel']
      if (params?.payeraccountid) {
        this.accountId = params?.payeraccountid;
      }
      if (params?.organizationid) {
        this.orgid = params?.organizationid;
      }
      this.emailId = params['emailid'];
      this.actionId = params['actionid'];
    })
    this.setCardControlsValidators();
  }
  setCardControlsValidators() {
    this.cardValidatorsData = {
      name_on_card: [Validators.required],
      zip: [Validators.pattern("^\\d{5}(-{0,1}\\d{4})?$"), Validators.minLength(5), Validators.maxLength(11)]
    };

  }
   

  ngOnInit() {

    this.initializeStripe();
    this.paymentForm = new FormGroup({
      account_id: new FormControl(""),
      transaction_type: new FormControl(),
      transaction_type_id: new FormControl(""),
      card: new FormGroup({
        email: new FormControl(""),
        name_on_card: new FormControl("", this.cardValidatorsData.name_on_card),
        zip: new FormControl("", this.cardValidatorsData.zip),
      }),
      //amount: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]{0,3}\.[0-9]{0,2}$"), Validators.max(999.99), Validators.minLength(1), Validators.maxLength(6)]),
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
      tip_amount: new FormControl(0),
      no_share_giving: new FormControl(false),
      payer_cover_amount: new FormControl(),
      allow_payer_cover_amount: new FormControl(true),
      sponsor_username: new FormControl(this.sponsorName),
      tip_percent: new FormControl(15),
      existing_payment_source: new FormControl(false),
      payment_source_id: new FormControl(null),
      future_use: new FormControl(false),
      payer_account_id: new FormControl(this.accountId),
      tracking_code: new FormControl(""),
      key_values: new FormControl([])
    });
    this.checkAPI();
  }

  private async initializeStripe() {
    let publishableKey = environment.stripe.publicKey;
    this.stripe = await loadStripe(publishableKey);
    this.elements = this.stripe.elements();
    this.loadStripeElement();


  }


  get f() {
    return this.paymentForm['controls']['card']['controls']
  }
  get a() {
    return this.paymentForm['controls']
  }

  setTip(data: any) {
    this.paymentForm.patchValue({
      tip_amount: Number(this.calculateTip())
    })
  }

  calculateTip() {

    let donationAmount = this.paymentForm.value.amount
    this.donationAmount = donationAmount

    let tip_percent = this.paymentForm.value.tip_percent

    this.calculatedTipAmount = donationAmount * ((tip_percent) / 100)
    return (this.calculatedTipAmount)
  }

  calculateTotalAmtForTip() {
    let total = Number(this.paymentForm.value.amount) + Number(this.calculatedTipAmount)
    return total
  }

  //calculateTotalAmt() {
  //  if (this.payerAmountValue === true) {
  //    let total = Number(this.paymentForm.value.amount) + this.calculateGiftFees()
  //    return total
  //  } else {
  //    let total = Number(this.paymentForm.value.amount) + 0.00
  //    return total
  //  }
  //}

  calculateTotalAmt() {
    let total = Number(this.paymentForm.value.amount) + this.calculateGiftFees()
    return total
  }
  giftFeesAmt() {
    let total = 0.00;
    if (this.payerCoverAmountData?.per_transaction_percent > 0) {
      total = parseFloat(this.paymentForm.value.amount) * this.payerCoverAmountData?.per_transaction_percent / 100
    }
    if (this.payerCoverAmountData?.per_transaction_amount > 0) {
      if (total == 0) {
        total = parseFloat(this.paymentForm.value.amount);
      }
      total = total + this.payerCoverAmountData?.per_transaction_amount
    }
    return total;
  }

  calculateGiftFees() {

    if (this.paymentForm.controls['allow_payer_cover_amount'].value) {
      return this.giftFeesAmt();
    }
    else {
      return 0;
    }

  }


  onSubmit() {
    this.submitted = true;
    if (this.paymentForm.controls['allow_payer_cover_amount'].value) {
      this.paymentForm.controls['payer_cover_amount'].setValue(Number(this.calculateGiftFees().toFixed(2)))
      this.paymentForm.controls['tip_percent'].setValue(0.00)
    } else {
      this.paymentForm.controls['payer_cover_amount'].setValue(0.00)
      this.paymentForm.controls['tip_percent'].setValue(0.00)
    }

    if (this.paymentForm.value.tracking_code != "Select Code" && this.paymentForm.value.tracking_code != "") {
      let selectedValue = this.arraytracking.find(x => x.code === this.paymentForm.value.tracking_code);
      this.paymentForm.patchValue({
        tracking_code: this.paymentForm.value.tracking_code,
        key_values: [{ trackingCode: selectedValue.label, value: selectedValue.code }]
      })
    }
    this.paymentForm.patchValue({
      amount: Number(this.paymentForm.value.amount),
      tip_amount: Number(this.calculatedTipAmount?.toFixed(2))
    })
    if (this.paymentForm.invalid) {
      for (let inner in this.paymentForm.controls) {
        this.paymentForm.get(inner).markAsTouched();
        this.paymentForm.get(inner).markAsDirty();
      }
      return;
    }
    let postData: any = { ...this.paymentForm.value };
    if (postData.existing_payment_source) {
      postData.payment_source_type = "charge_card";
      postData.card = null;
      this.spinnerService.show();
      this.postGiveNow(postData);
    }
    else {
      this.createPaymentMethodAndPostData();
    }



  }

  createPaymentMethodAndPostData() {
    this.spinnerService.show();
    const cardHolderName = this.paymentForm.value.card.name_on_card;
    let paymentMethodParams: any = {
      type: 'card',
      card: this.cardNumber,
      billing_details: {
        name: cardHolderName,
        email: this.paymentForm.value.card.email,
      }
    }

    if (this.paymentForm.value.card.zip && this.paymentForm.value.card.zip.trim()) {
      paymentMethodParams.billing_details.address = {
        postal_code: this.paymentForm.value.card.zip.trim()
      }
    }
    if (this.paymentForm.value.card.email && this.paymentForm.value.card.email.trim()) {
      paymentMethodParams.billing_details.email = this.paymentForm.value.card.email.trim()
    }
    this.stripe.createPaymentMethod(paymentMethodParams)
      .then((result) => {
        if (result.error) {
          this.spinnerService.hide();
          this.toastMessage.Error(result.error.message, 3000);
          return;
        }
        let postData: any = { ...this.paymentForm.value };
        postData.payment_source_id = result.paymentMethod.id;
        postData.payment_source_type = "charge_card";

        postData.payer_name = this.paymentForm.value.card.name_on_card;
        postData.payer_email = this.paymentForm.value.card.email;
        postData.payer_zip = this.paymentForm.value.card.zip;
        postData.card = null;
        //postData.paymentIntentId = paymentIntentId;
        this.postGiveNow(postData);


      }).catch(error => {
        this.spinnerService.hide();

      });
  }

  postGiveNow(postData: any) {
    let accountid = postData.account_id
    let campaignid = postData.transaction_type_id
    if (this.emailId && this.actionId) {
      this.updateActionTracking(this.emailId, this.actionId, accountid, postData);
    }
    this.giveNowService.postPaymentDetails(postData).subscribe(res => {
      if (res.status == "succeeded") {
        this.spinnerService.hide();
        let id = 1;

        if (this.whitelabel == '0') {
          if (this.orgid) {
            this.route.navigate(['paysuccess', this.whitelabel, this.orgid]);
          }
          else {
            this.route.navigate(['paysuccess']);
          }
          
          
        } else {
          this.route.navigate(['paysuccess', id, accountid, campaignid]);
        }
      }
      else if (res.status == "requires_action") {
        this.stripe.handleCardAction(res.details.payment_intent_client_secret).then((result) => {
          if (result.error) {
            this.toastMessage.Error(result.error.message);
            // redirect to error payment failed
            this.spinnerService.hide();
          } else {
            // call the server;
            postData.payment_intent_id = res.details.payment_intent_id;
            this.postGiveNow(postData);
          }

        })
      }
      else {
        this.spinnerService.hide();
        this.redirecToFailed();
        //this.route.navigate(['payfailed']);
      }
    },
      error => {
        //this.route.navigate(['payfailed']);
        this.spinnerService.hide();
        this.redirecToFailed();

      });
  }

  redirecToFailed() {
    if (this.whitelabel == '0') {
      if (this.orgid) {
        this.route.navigate(['payfailed', this.whitelabel, this.orgid]);
      }
      else {
        this.route.navigate(['payfailed']);
      }
    } else {
      this.route.navigate(['payfailed']);
    }
  }
  checkAPI() {
    if (this.fatchedParams) {
      let textData = this.fatchedParams.data;
      let ArrayOfParams = textData.split(":");
      this.ParamsArray.push(ArrayOfParams);
      this.getQrCodeUrlData(this.ParamsArray[0][2]);
      this.setAccountId(this.ParamsArray[0][1]);
      this.setTransactionType(this.ParamsArray[0][0]);
      this.setTansactionTypeId(this.ParamsArray[0][2]);
      if (this.ParamsArray.length > 0 && this.ParamsArray[0].length > 4) {
        this.setSponserName(this.ParamsArray[0][4]);
      }
      this.getTrackingData(this.ParamsArray[0][2])

    }
  }

  setSponserName(sponserName: any) {
    this.paymentForm.patchValue({
      sponsor_username: sponserName
    })

  }

  setAccountId(data) {
    this.paymentForm.patchValue({
      account_id: data
    })
  }
  setTransactionType(data) {
    this.paymentForm.patchValue({
      transaction_type: Number(data)
    })
  }

  setTansactionTypeId(data) {
    this.paymentForm.patchValue({
      transaction_type_id: data
    })
  }

  getQrCodeUrlData(sourceId: string) {
    this.spinnerService.show();
    this.qrcode_id = sourceId;
    this.giveNowService.getQRCodeURLInfo(sourceId).subscribe(res => {
      this.QRData = res;
      this.QR_info_data = JSON.parse(this.QRData?.qr_info_data);
      this.orgNameAndLogo();

      if (this.QR_info_data?.intake_rules?.require_email) {
        this.cardValidatorsData.email = [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')];
        this.paymentForm.get('card').get('email').setValidators(this.cardValidatorsData.email)
        this.paymentForm.get('card').get('email').updateValueAndValidity();
      }
      if (this.QR_info_data?.campaign != null) {
        if (this.QR_info_data?.owner == this.QR_info_data?.campaign) {
          this.isCampaign = false;
        } else {
          this.isCampaign = true;
          this.campaignName = this.QR_info_data?.campaign;
        }
      } else {
        this.isCampaign = false;
      }
      if (this.QR_info_data?.intake_rules?.require_tracking_code) {
        this.isTrackingCode = true
      } else {
        this.isTrackingCode = false
      }
      this.getPaymentSources();
      //this.spinnerService.hide();
    })
  }

  orgNameAndLogo() {
    this.organizationName = this.QR_info_data?.owner
    this.orgLogo = this.QR_info_data?.small_logo_path
  }

  expiryBugMonth(data) {
    let selectedValue = data?.selectedIndex
    if (Number(data[selectedValue].text) <= this.currentMonth[0].value) {
      this.redFlag = true
    }
    if (Number(data[selectedValue].text) > this.currentMonth[0].value) {
      this.redFlag = false
    }
  }


  getStripeData() {
    this.spinnerService.show();
    let postData: any = {
      "processor": "stripe",
      "payment_method": "charge_card",
      "currency": "usd"
    };

    this.giveNowService.getStripeData(postData).subscribe(res => {
      this.spinnerService.hide();
      this.stripeSetupData = res;

      this.elements = this.stripe.elements(
        { clientSecret: this.stripeSetupData.new_client_secret }
      );
      this.loadStripeElement();
    })
  }

  loadStripeElement() {
    if (this.cardNumber) {
      this.cardNumber.unmount();
      this.cardExpiry.unmount();
      this.cardCvc.unmount();
    }
    this.mountCardNumberElement();
    this.mountCardExpiryElement();
    this.mountCardCVCElement();
  }

  private mountCardNumberElement() {
    const cardNumberOptions: StripeCardNumberElementOptions = {
      showIcon: true,
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,
      iconStyle: 'solid',
    };

    // card number
    this.cardNumber = this.elements.create('cardNumber', cardNumberOptions);
    this.cardNumber.mount('#card-number-element');
    this.cardNumber.on('change', event => {
      if (event.complete) {
        this.stripeCardNumberErrorMessage = null;
      } else if (event.error) {
        this.stripeCardNumberErrorMessage = event.error.message;
      }
    });
  }

  mountCardExpiryElement() {
    const cardExpiryOptions: StripeCardExpiryElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardExpiry = this.elements.create('cardExpiry', cardExpiryOptions);
    this.cardExpiry.mount('#card-exp-element');

    this.cardExpiry.on('change', event => {
      if (event.complete) {
        this.stripeExpiryErrorMessage = null;
      } else if (event.error) {
        this.stripeExpiryErrorMessage = event.error.message;
      }
    });
  }

  mountCardCVCElement() {
    const cardCVCOptions: StripeCardCvcElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardCvc = this.elements.create('cardCvc', cardCVCOptions);
    this.cardCvc.mount('#card-cvc-element');

    this.cardCvc.on('change', event => {
      if (event.complete) {
        this.stripeCVCErrorMessage = null;
      } else if (event.error) {
        this.stripeCVCErrorMessage = event.error.message;
      }
    });
  }

  getCardOptions() {
    let cardOptions: StripeCardNumberElementOptions = {
      classes: {
        base: 'form-control',
        invalid: 'is-invalid',
        complete: 'is-valid',
        focus: "form-control stripeelementfocus",
        empty: "form-control"
      },
      style: {
        base: {
          //iconColor: '#007bff',
          //color: '#495057',
          lineHeight: '1.7',
          //fontWeight: 300,
          //fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          //fontSize: '16px',
          '::placeholder': {
            color: 'rgba(33, 37, 41, 0.75)'
          },

        },
        invalid: {
          iconColor: '#dc3545',
          color: '#dc3545'
        },
      }
    }
    return cardOptions;
  }

  getPaymentSources() {

    if (this.accountId) {
      this.giveNowService.getWallet(this.accountId).subscribe(
        (res: any) => {
          // this.spinnerService.hide(this.paymentSourceSpinnerName);
          //this.spinnerService.hide();
         
          if (!res) {
            this.paymentForm.controls["payer_account_id"].setValue(null);
            return;

          }
          this.paymentSourceData = res;
          if (res && res.charge_cards && res.charge_cards.length > 0) {

            this.paymentSourceData.charge_cards = this.paymentSourceData?.charge_cards.filter(b => {
              return b.status_state?.toLocaleLowerCase() == 'ready'
            });

            if (this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0) {
              this.paymentForm.controls["existing_payment_source"].setValue(true);
              this.selectExistingPaymentChange();
              return;
            }
          }

        },
        error => {
          // handle error
          this.paymentForm.controls["payer_account_id"].setValue(null);
          this.spinnerService.hide();
        },
        () => {
          this.getPayerCoverAmount();
        }
      );
    }
    else {
      this.getPayerCoverAmount();
      // this.spinnerService.hide();
    }

  }

  payerCoverAmountData: any;

  getPayerCoverAmount() {
    this.spinnerService.show();
    this.giveNowService.getPayerCoverAmount(this.QR_info_data.owner_id).subscribe(
      (res: any) => {
        this.payerCoverAmountData = res;

        this.spinnerService.hide();




      },
      error => {
        // handle error
        this.spinnerService.hide();
      },
      () => {
        //if (!this.payerCoverAmountData)
        //this.payerCoverAmountData = {
        //  "per_transaction_amount": 0.25,
        //  "per_transaction_percent": 5.0
        //}
      }
    );

  }

  selectExistingPaymentChange() {
    timer(200).subscribe(() => {
      this.paymentForm.controls["payment_source_id"].clearValidators();
      this.paymentForm.controls["payment_source_id"].reset();
      this.paymentForm.controls["payment_source_id"].setValue("");
      if (this.paymentForm.value.future_use) {
        this.paymentForm.controls["future_use"].setValue(false);
      }


      if (this.paymentForm.value.existing_payment_source) {
        this.paymentForm.controls["payment_source_id"].addValidators(Validators.required);
        let cardForm = this.paymentForm['controls']['card'] as FormGroup;
        cardForm.get('name_on_card').clearValidators();
        cardForm.get('name_on_card').updateValueAndValidity();
        cardForm.get('zip').clearValidators();
        cardForm.get('zip').updateValueAndValidity();
        cardForm.get('email').clearValidators();
        cardForm.get('email').updateValueAndValidity();
      }
      else {
        this.paymentForm.controls["payment_source_id"].reset();

        this.resetCardControls();
        timer(500).subscribe(() => {
          this.elements = this.stripe.elements();
          this.loadStripeElement();
        });

      }
      this.paymentForm.controls['payment_source_id'].updateValueAndValidity();
    });


  }

  resetCardControls() {
    this.stripeCardNumberErrorMessage = null;
    this.stripeExpiryErrorMessage = null;
    this.stripeCVCErrorMessage = null;
    let cardForm = this.paymentForm['controls']['card'] as FormGroup;
    cardForm.markAsUntouched();
    cardForm.reset();
    cardForm.get('name_on_card').addValidators(this.cardValidatorsData.name_on_card);
    cardForm.get('zip').addValidators(this.cardValidatorsData.zip);
    if (this.QR_info_data?.intake_rules?.require_email && this.cardValidatorsData.email) {
      cardForm.get('email').setValidators(this.cardValidatorsData.email)
    }
  }

  getTrackingData(campaignid: string) {
    this.giveNowService.getTrackingData(campaignid).subscribe(res => {
      this.trackingDetails = res;
      this.arraytracking = this.trackingDetails?.tracking_codes;
    })
  }

  updateActionTracking(emailId: string, actionId: string, accountId: any, postData: any) {
    //  const totalAmountWithTip = this.calculateTotalAmtForTip();
    // const totalAmountWithFees = this.calculateTotalAmt();
    const donationAmount = postData.amount
    const payload = {
      account_id: accountId,
      email_id: emailId,
      action_id: actionId,
      action_tracking: {
        action_id: actionId,
        action_name: "Give Now",
        action_page: true,
        action_amount: donationAmount,
        action_outcome: "",
        campaign_id: this.qrcode_id
      }
    };

    // if (this.QR_info_data?.intake_rules?.require_tips) {
    //   payload.action_tracking.action_amount = totalAmountWithTip
    // } else {
    //   payload.action_tracking.action_amount = totalAmountWithFees
    // }

    // Call your API service to update the API
    this.giveNowService.getActionTracking(emailId, payload).subscribe(
      (res: any) => {
        // console.log('API updated successfully:', res);
      },
      (error: any) => {
        console.error('Error updating API:', error);
      }
    );
  }

}



