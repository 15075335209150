import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PayNowComponent } from './pay-now/pay-now.component';
import { PayNowFailedComponent } from './pay-now/pay-now-failed/pay-now-failed.component';
import { PayNowSuccessComponent } from './pay-now/pay-now-success/pay-now-success.component';
import { PayNowStripeComponent } from './pay-now/pay-now-stripe/pay-now-stripe.component';
import { PayNowStoreComponent } from './pay-now/pay-now-store/pay-now-store.component';
import { PayNowOrderComponent } from './pay-now/pay-now-order/pay-now-order.component';
import { PayNowEventComponent } from './pay-now/events/pay-now-event/pay-now-event.component';
import { EventOrderComponent } from './pay-now/events/event-order/event-order.component';
import { PayNowRaffleComponent } from './pay-now/raffle/pay-now-raffle/pay-now-raffle.component';
import { PayNowOpenauctionComponent } from './pay-now/auction/pay-now-openauction/pay-now-openauction.component';
import { PayNowPledgeComponent } from './pay-now/pay-now-pledge/pay-now-pledge.component';

const routes: Routes = [
  { path: '', component: PayNowStripeComponent },
  { path: 'paynow', component: PayNowComponent },
  { path: 'paysuccess', component: PayNowSuccessComponent },
  { path: 'paysuccess/:organizationid', component: PayNowSuccessComponent },
  //{ path: 'paysuccess/:whitelabel', component: PayNowSuccessComponent },
  //{ path: 'paysuccess/:whitelabel/:organizationid', component: PayNowSuccessComponent },
  //{ path: 'paysuccess/:whitelabel/:organizationid/:campaignid', component: PayNowSuccessComponent },
  { path: 'payfailed', component: PayNowFailedComponent },
  { path: 'payfailed/:organizationid', component: PayNowFailedComponent },
  { path: 'store', component: PayNowStoreComponent },
  { path: 'order/:orderid', component: PayNowOrderComponent },
  { path: 'event', component: PayNowEventComponent },
  { path: 'event/order/:orderid', component: EventOrderComponent },
  { path: 'raffle', component: PayNowRaffleComponent },
  { path: 'auction', component: PayNowOpenauctionComponent },
  { path: 'pledge', component: PayNowPledgeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
