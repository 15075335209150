export const environment = {
  production: false,
  apiUrl: "",
  loginUrl: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/login",
  businessUrl: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/location",
  profileUrl: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/profile",
  merchantRegistration: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development",
  phoneCodeVerification: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/phone/verification",
  phoneVerificationStatus: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/phone/verification/status",
  resendCodeVerification: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/phone/resendcode",
  monthlyTransaction:"https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/transactions",
  forgotPasswordUrl: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/password",
  verifyAccountUrl: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/verify",
  salesBreakdownUrl: "https://52c0gchss8.execute-api.us-east-1.amazonaws.com/development/overview/organization",
  customersUrl: "https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development/customers",
  createOrg: "https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development",
  donate: "https://s52elofbwi.execute-api.us-east-1.amazonaws.com/development/donate",
  offering: "https://s52elofbwi.execute-api.us-east-1.amazonaws.com/development/offering/",
  relieffund: "https://s52elofbwi.execute-api.us-east-1.amazonaws.com/development/relieffund/",
  hello: "https://7vncpa6jk8.execute-api.us-east-1.amazonaws.com/prod/hello/",
  donationSuccess: "https://scan2pay.io/donate/donatestatus.html?status=0",
  donationFailure: "https://scan2pay.io/donate/donatestatus.html?status=1",
  donationChangeMyMind: "https://scan2pay.io/donate/donatestatus.html?status=-1",
  cryptoWallet: "https://3j3hld5oda.execute-api.us-east-1.amazonaws.com/development/CryptoWallet",
  funderPartAccount: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development",
  fundPartRegistration: "https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development",
  fundProjectCreate: "https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development/fundingprojects",
  fundGetProject: "https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development/fundingprojects/available",
  uploadProjectImage:"https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development/fundingprojects/image/upload",
  getProjectImages:"https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development/fundingprojects/images",
  getProjectDetail:"https://ho8pzjojbh.execute-api.us-east-1.amazonaws.com/development/fundingprojects",
  getFunderList:"https://p4sw6fj9y9.execute-api.us-east-1.amazonaws.com/development/investors",
  uploadProfileImage:"https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/profile/image",
  exchangeCurrencyGet:"https://uzj8f3xcul.execute-api.us-east-1.amazonaws.com/development/cash/exchange/",
  getWallet:"https://uzj8f3xcul.execute-api.us-east-1.amazonaws.com/development/",
  postWallet:"https://uzj8f3xcul.execute-api.us-east-1.amazonaws.com/development/card",
  paymentSource:"https://14mpqgg1ee.execute-api.us-east-1.amazonaws.com/development/pay/paymentsources",
  fundData:"https://p4sw6fj9y9.execute-api.us-east-1.amazonaws.com/development/fund/investment",
  cardData:"https://uzj8f3xcul.execute-api.us-east-1.amazonaws.com/development/wallet/card",
  registerFunder:"https://lv2jozjial.execute-api.us-east-1.amazonaws.com/sandbox/registercustomer",
  attachBank:"https://lv2jozjial.execute-api.us-east-1.amazonaws.com/sandbox/attachbank",
  payOut:"https://lv2jozjial.execute-api.us-east-1.amazonaws.com/sandbox/payout",
  getBank:"https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/wallet",
  fundPayment:"https://p4sw6fj9y9.execute-api.us-east-1.amazonaws.com/development/fund/project",
  KEY_FOR_OUTER_URL: 'spQuery',
  //one time payment API
  payone : "https://14mpqgg1ee.execute-api.us-east-1.amazonaws.com/development/pay/onetime",
  imageLogo: "https://gks3rjj4ph.execute-api.us-east-1.amazonaws.com/development/",
  account_Id: "6f407f43-763a-4472-abb4-eccff9b7b636",

  //ScanPay Api
  //giveNow: "https://6op50ujgu6.execute-api.us-east-1.amazonaws.com/dev/give/onetime",
  //qrCode: "https://6op50ujgu6.execute-api.us-east-1.amazonaws.com/dev/qrcode/",
  baseUrlGive: 'https://6op50ujgu6.execute-api.us-east-1.amazonaws.com/dev/',
  stripe: {
    publicKey: 'pk_test_51Ogxa2JW6TJGwaxSkowKDyVwmJkLuowZrIaX0RGuiFhMJeJHU803t4aQrLijKwNpM7j135ppVXL0MAE1ZlDHSMKu00HibfQrVu'
  },
  whitelable_domain_url: 'https://stage.campaign.gogiveanywhere.com',
  website_domain:'stage.gogiveanywhere'
};
