<!-- <div class="container">
  <div *ngIf="isLoading" class="text-centre">
    <div id="loader"></div>
    <div id="backdrop"></div>
  </div>
</div> -->
<!-- payment section -->
<div class="container-fluid min-vh-100 dec-value-card bg-new">
  <div class="card-details-main min-vh-100 py-4">
    <div class="card-details-container">
      <div class="row justify-content-between">
        <div class=" align-self-center col-8 col-lg-8 col-md-8 col-sm-7 top-header ">
          <div class="org-name">{{this.organizationName}}</div>

        </div>
        <div class="align-self-center col-3 col-lg-2 col-md-2 col-sm-2 col-xl-2 px-3 pb-1">
          <img class="" [src]="this.orgLogo">
        </div>
      </div>
      <div class="row justify-content-between">
        <ng-container *ngIf="isCampaign">
          <div class="textsize mb-2" style="font-weight: 700;font-size: 15px;">{{this.campaignName}}</div>
        </ng-container>
      </div>
      <div class="panel-body textsize">
        <form name="form" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
          <div>
            <div class="row mb-1 mb-lg-2 mb-md-2 mb-sm-2"
              *ngIf="this.paymentForm.value.payer_account_id && this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0">
              <!--<div class="form-group col-12">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="existing_payment_source" [value]="true" name="existing_payment_source" formControlName="existing_payment_source" (change)="selectExistingPaymentChange()">
                  <label class="form-check-label" for="existing_payment_source">Select Existing Payment Source</label>
                </div>
              </div>-->
              <div class="form-group col-md-6">
                <div class="form-check">
                  <!-- <input type="radio" id="selectExisting" class="form-check-input" [value]="true"
                    name="existing_payment_source" formControlName="existing_payment_source"
                    (change)="selectExistingPaymentChange()" /> -->
                  <ejs-radiobutton label=" Select Existing Payment Source" name="existing_payment_source" [value]="true" class="form-check-input form-check-label" id="selectExisting"
                                   formControlName="existing_payment_source" (change)="selectExistingPaymentChange()"></ejs-radiobutton>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="form-check">
                  <!-- <input type="radio" id="selectNew" class="form-check-input" [value]="false"
                    name="existing_payment_source" formControlName="existing_payment_source"
                    (change)="selectExistingPaymentChange()" /> -->
                  <ejs-radiobutton label="Create New Payment Source" name="existing_payment_source" [value]="false" class="form-check-input"
                                   id="selectNew" formControlName="existing_payment_source"
                                   (change)="selectExistingPaymentChange()"></ejs-radiobutton>
                </div>
              </div>
            </div>
            <div class="row mb-1 mb-lg-2 mb-md-2 mb-sm-2"
              *ngIf="this.paymentForm.value.existing_payment_source && this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0">
              <div class="form-group col-sm-12">
                <select class="form-select " formControlName="payment_source_id"
                  [ngClass]="{'is-invalid': a['payment_source_id'].touched && a['payment_source_id'].errors, 'is-valid': a['payment_source_id'].valid}">
                  <option value="">Select Payment Source</option>
                  <option *ngFor="let card of paymentSourceData?.charge_cards" [value]="card.card_id">
                    {{card.brand_of_card}} - {{card.last_four}}
                  </option>
                </select>

                <div *ngIf="a['payment_source_id'].invalid &&
                           (a['payment_source_id'].touched || a['payment_source_id'].dirty )">
                  <small class="text-danger" *ngIf="a['payment_source_id'].errors['required']">
                    Please select payment source
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div formGroupName="card" *ngIf="!this.paymentForm.value.existing_payment_source">
            <!-- name -->
            <div class="row mb-1 mb-lg-2 mb-md-2 mb-sm-2">
              <!-- <div class="form-group col-12">
                <input type="text" class="form-control" id="name_on_card" name="name_on_card" placeholder="Name on Card"
                  formControlName="name_on_card"
                  [ngClass]="{'is-invalid': f['name_on_card'].touched && f['name_on_card'].errors, 'is-valid': f['name_on_card'].valid}"
                  required />
                
                <div class="">
                  <small class="text-danger"
                    *ngIf="(f['name_on_card'].touched ||  f['name_on_card'].dirty ) && f['name_on_card'].errors">
                    Name is required
                  </small>
                </div>
              </div> -->
              <div class="form-group template">
                <!-- <div class="e-float-input">
                  <input type="text" id="name" name="name" formControlName="name_on_card" required>
                  <span class="e-float-line"></span>
                  <label class="e-float-text">Name on Card</label>
                </div> -->

                <ejs-textbox class="" id="name_on_card" name="name_on_card" placeholder="Name on Card"
                   floatLabelType="Never" aria-errormessage="Name is required" formControlName="name_on_card"
                  required></ejs-textbox>
                <span class="e-error"
                  *ngIf="(f['name_on_card'].touched ||  f['name_on_card'].dirty ) && f['name_on_card'].errors">
                  Name is required
                </span>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-12 my-1 my-lg-2 my-md-2 my-sm-2">
                <div id="card-number-element" style="border-radius: 0%;">
                </div>

                <div *ngIf="stripeCardNumberErrorMessage" class="">
                  <small class="text-danger">
                    {{stripeCardNumberErrorMessage}}
                  </small>
                </div>
              </div>
              <div class="col-4  my-1 my-lg-2 my-md-2 my-sm-2">
                <div id="card-exp-element" style="border-radius: 0%;"></div>

                <div *ngIf="stripeExpiryErrorMessage" class="">
                  <small class="text-danger">
                    {{stripeExpiryErrorMessage}}
                  </small>
                </div>
              </div>
              <div class="col-4  my-1 my-lg-2 my-md-2 my-sm-2">
                <div id="card-cvc-element" style="border-radius: 0%;"></div>
                <div *ngIf="stripeCVCErrorMessage" class="">
                  <small class="text-danger">
                    {{stripeCVCErrorMessage}}
                  </small>
                </div>
              </div>
              <div class="col-4 my-1 my-lg-2 my-md-2 my-sm-2">
                <!-- <input type="text" class="form-control" style="border-radius: 0%;" name="zip" placeholder="Zip"
                  formControlName="zip"
                  [ngClass]="{'is-invalid': f['zip'].errors, 'is-valid': f['zip'].touched && f['zip'].valid}" /> -->
                  
                <ejs-textbox type="number" style="border-radius: 0%;" name="zip" placeholder="Zip" 
                  formControlName="zip" minlength="5" maxlength="11"></ejs-textbox>
                
                <span class="e-error" *ngIf="(f.zip.touched || f.zip.dirty) && f.zip.errors?.minlength">
                  Minimum 5 digits
                </span>
                <span class="e-error" *ngIf="(f.zip.touched || f.zip.dirty) && f.zip.errors?.maxlength">
                  Maximum 11 digits
                </span>
              </div>
            </div>

            <!-- Email and Zip -->
            <div class="form-group row">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 my-1 my-lg-2 my-md-2 my-sm-2">
                <!-- <input type="email" class="form-control" name="email" placeholder="Email" formControlName="email"
                    [ngClass]="{ 'is-invalid': f['email'].touched && f['email'].errors,'is-valid': f['email'].valid}" />
                  <div *ngIf="f['email'].invalid &&
                           (f['email'].touched || f['email'].dirty )">
                    <small class="text-danger" *ngIf="f['email'].errors['required']">
                      Email is required
                    </small>
                    <small class="text-danger" *ngIf="f['email'].errors['email'] || f['email'].errors['pattern']">
                      Please enter valid email address
                    </small>
                  </div> -->
                <ejs-textbox class="" id="name_on_card" name="email" placeholder="Email"  floatLabelType="Never"
                  formControlName="email" required></ejs-textbox>
                <!-- <span class="e-error" *ngIf="f['email'].invalid && (f['email'].touched || f['email'].dirty )">
                    Email is required
                  </span>
                  <small class="e-error" *ngIf="f['email'].errors['email'] || f['email'].errors['pattern']">
                    Please enter valid email address
                  </small> -->
                <div *ngIf="f['email'].invalid && (f['email'].touched || f['email'].dirty )">
                  <small class="e-error" *ngIf="f['email'].errors['required']">
                    Email is required
                  </small>
                  <small class="e-error" *ngIf="f['email'].errors['email'] || f['email'].errors['pattern']">
                    Please enter valid email address
                  </small>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-12  my-1 my-lg-2 my-md-2 my-sm-2 d-flex gap-1 align-items-center"
              *ngIf="!this.paymentForm.value.existing_payment_source && this.paymentForm.value.payer_account_id">
              <div class="form-check">
                <!-- <input class="form-check-input" type="checkbox" name="future_use" placeholder=""
                  formControlName="future_use" id="future_use">
                <label class="form-check-label" for="future_use" style="font-size:11px;">
                  Save this payment method for future usage
                </label> -->

                <ejs-checkbox class="form-check-input" for="future_use" style="font-size:11px;"
                  label="Save this payment method for future usage" cssClass="e-small" name="future_use"
                  formControlName="future_use" id="future_use"></ejs-checkbox>
              </div>
            </div>
          </div>
          <!-- Amount input -->
          <div class="form-group resp mb-0 mb-lg-2 mb-xl-2 mb-sm-2 mb-md-2">
            <div class="row align-items-center">
              <div class="col-6 col-lg-6 col-md-6 col-sm-6 my-1 my-lg-2 my-md-2 my-sm-2 ">
                <!--<div class="col-6   mb-2">-->
                <div class="">
                  Donation Amount
                </div>
              </div>
              <div class="col-6 col-lg-6 col-md-6 col-sm-6 my-1 my-lg-2 my-md-2 my-sm-2">
                <!--<div class="col-6   mb-2">-->
                <div class="new-chat-window">
                  <i class="fa dollar">$</i>
                  <input type="number" class="form-control new-chat-window-input px-4" style="border-radius: 0%;"
                    positivenumeric id="new-chat-window-input" name="amount" placeholder="0.00" formControlName="amount"
                    [ngClass]="{'is-invalid': a['amount'].errors && a['amount'].touched,'is-valid': a['amount'].valid }" />
                </div>
                <div *ngIf="a['amount'].invalid &&
                           (a['amount'].touched || a['amount'].dirty )">
                  <small class="text-danger" *ngIf="a['amount'].errors['required']">
                    Amount is required
                  </small>
                  <small class="text-danger" *ngIf="a['amount'].errors['min'] ">
                    Amount should be {{a['amount'].errors['min']['min'] | currency:'USD' }} or greater than
                    {{a['amount'].errors['min']['min'] | currency:'USD'}}
                  </small>
                </div>
              </div>

              <!-- ----tracking Code----- -->
              <ng-container *ngIf="isTrackingCode">
                <div class="col-6 col-lg-6 col-md-6 col-sm-6 my-1 my-lg-2 my-md-2 my-sm-2 ">
                  <div class="">
                    {{trackingDetails.label}}
                  </div>
                </div>
                <div class="col-6 col-lg-6 col-md-6 col-sm-6 my-1 my-lg-2 my-md-2 my-sm-2 ">
                  <div class="tracking">
                    <select class="form-control" formControlName="tracking_code">
                      
                      <option selected>Select Code</option>
                      
                      <option *ngFor="let item of arraytracking">{{item}}</option>
                    </select>
                  </div>
                  <!-- <small class="text-danger" *ngIf="a['show_trackingcode'].errors['required']">
                      Tracking code is required
                    </small> -->
                </div>
              </ng-container>

              <!-- {{arraytracking | json}} -->

              <div class="col-12  my-1 my-lg-2 my-md-2 my-sm-2 d-flex gap-1 align-items-center">
                <div class="form-check">
                  <!-- <input class="form-check-input" type="checkbox" name="nosharegiving" placeholder=""
                    formControlName="no_share_giving" id="nosharegiving">
                  <label class="form-check-label" for="nosharegiving" style="font-size:11px;">
                    Don't display my name publicly on the fundraiser <img class="about"
                      src="../../assets/images/about.png" width="17" height="17">
                  </label> -->
                  <ejs-checkbox class="form-check-input" style="padding-right: 5px;" name="nosharegiving"
                    formControlName="no_share_giving" id="nosharegiving"
                    label="Don't display my name publicly on the fundraiser" for="nosharegiving"
                    cssClass="e-small"></ejs-checkbox>
                  <img class="about ml-2" src="../../assets/images/about.png" width="13" height="13">
                </div>
              </div>

              <div class="col-12  my-1 my-lg-2 my-md-2 my-sm-2 d-flex gap-1 align-items-center"
                *ngIf="!this.QR_info_data?.intake_rules?.require_tips">
                <div class="form-check">
                  <!-- <input class="form-check-input" type="checkbox" name="payer_cover_amount" placeholder="" checked (change)="togglePayerAmount($event)"
                    id="payer_cover_amount">
                  <label class="form-check-label" for="payer_cover_amount" style="font-size:11px;">
                    Increase my gift by ${{ (this.calculateGiftFees() | number: '1.2-2') }} to offset processing
                    fees.
                  </label> -->
                  <ejs-checkbox class="form-check-input" name="allow_payer_cover_amount"
                                formControlName="allow_payer_cover_amount" 
                    id="allow_payer_cover_amount"
                    label="Increase my gift by ${{ (this.giftFeesAmt() | number: '1.2-2') }} to offset processing fees."
                    for="allow_payer_cover_amount" cssClass="e-small"
                    style="font-size:11px;"></ejs-checkbox>
                </div>
              </div>
            </div>
          </div>

          <!-- progress bar -->
          <div *ngIf="this.QR_info_data?.intake_rules?.require_tips">
            <div class="progress-bar-new"></div>
            <div>
              <div class="d-flex justify-content-center">
                GiveAnywhere Platform Tip
              </div>
              <div class="d-flex justify-content-center">
                {{this.paymentForm.value.tip_percent}}%
              </div>
              <div class="row justify-content-center gap-2 ">
                <div class="col-1">0%</div>
                <div class=" align-self-center col-7 col-lg-9 col-md-9 col-sm-9 col-xl-9">
                  <!-- <div class="progress">
                    <div class="progress-bar" style="width: 50%; background-color: #6dd162;" role="progressbar"
                      aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> -->
                  <input type="range" class="form-range" min="0" max="30" step="1" id="customRange2" #slider
                    (change)="setTip($event.target)" formControlName="tip_percent">
                </div>
                <div class="col-1">30%</div>
              </div>
              <!-- [value]="this.paymentForm.value.tip_amount" -->

              <div class="d-flex justify-content-center textsize1">
                GiveAnywhere does not charge nonprofits and cause organizers to process donations.
              </div>
              <div class="d-flex justify-content-center pb-0 pb-xl-3 pb-lg-3 pb-md-3 pb-sm-3 textsize1">
                The Platform is supported by the generosity of donors like you.
              </div>
            </div>
          </div>

          <!-- calculations -->
          <div class="progress-bar-new"></div>

          <div class="d-flex justify-content-center new-text">
            Your Donation
          </div>
          <div class="row justify-content-center p-1 textsize">
            <div class="col-4">
              Donation
            </div>

            <div class="col-3 px-5 textsize" *ngIf="!this.paymentForm.value.amount">
              $0.00
            </div>
            <div class="col-3 px-5 textsize" *ngIf="this.paymentForm.value.amount > 0">
              ${{this.paymentForm.value.amount | number: '1.2-2' }}
            </div>
          </div>


          <ng-container *ngIf="this.QR_info_data?.intake_rules?.require_tips">
            <div class="row justify-content-center p-1 textsize">
              <div class="col-4">
                GiveAnywhere Tip
              </div>
              <div class="col-3 px-5 textsize">
                ${{this.calculateTip() | number: '1.2-2'}}
              </div>
            </div>
            <div class="row justify-content-center p-1 textsize">
              <div class="col-4">
                Total
              </div>
              <div class="col-3 px-5 textsize">
                ${{this.calculateTotalAmtForTip() | number: '1.2-2'}}
              </div>
            </div>
          </ng-container>

          <ng-container >
            <!-- {{setPayerCoverAmount()}} -->
            <div class="row justify-content-center p-1 textsize" *ngIf="this.paymentForm.value.allow_payer_cover_amount">
              <div class="col-4">
                Processing Fees
              </div>
              <div class="col-3 px-5 textsize">
                ${{this.calculateGiftFees() | number: '1.2-2'}}
              </div>
            </div>
            <div class="row justify-content-center p-1 textsize">
              <div class="col-4">
                Total
              </div>
              <div class="col-3 px-5 textsize">
                ${{this.calculateTotalAmt() | number: '1.2-2'}}
              </div>
            </div>
          </ng-container>
          <!-- Gift fees start -->

          <!-- end -->


          <div class="d-flex mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3 mt-0">
            <button class="btn pay-now " type="submit" [disabled]="!this.paymentForm.valid">
              Give Now
            </button>
          </div>
          <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
        </form>
      </div>
    </div>
  </div>
</div>
