import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs';
import { GiveNowService } from 'src/app/_services/give-now.service';

@Component({
  selector: 'app-event-order',
  templateUrl: './event-order.component.html',
  styleUrls: ['./event-order.component.css']
})
export class EventOrderComponent implements OnInit, OnDestroy {

  fatchedParams: any;
  type: any;
  orderId: any;
  date: any
  name: any;
  email: any;
  phone: any;
  address: any;
  receipt_Url: any;
  status: any;
  organization: any;
  orderDetails: any;
  constructor(private activatedRoute: ActivatedRoute, private giveNowService: GiveNowService, private router: Router, private spinnerService: NgxSpinnerService) {
    this.receipt_Url = this.router.getCurrentNavigation().extras?.state?.['Url'];
  }



  ngOnInit() {
    this.orderId = this.activatedRoute.snapshot.params['orderid'];
    this.eventTicketData();

  }
  ngOnDestroy(): void {
    this.spinnerService.hide();
  }



  eventTicketData() {
    this.spinnerService.show();
    this.giveNowService.getEventTicketOrderData(this.orderId).subscribe(res => {
      this.spinnerService.hide();
      this.orderDetails = res;


      

    })
  }

  getPrice(orderItem: any) {
    return orderItem.price_category ? orderItem.price_category?.price : orderItem?.ticket_type?.price;
  }
  getEventAddress(eventData: any) {
    let address: any = [];
    let locationAddress = "";
    if (eventData?.location) {
      if (eventData?.location?.street_1 && eventData?.location?.street_1?.trim()) {
        address.push(eventData?.location?.street_1?.trim());
      }
      if (eventData?.location?.street_2 && eventData?.location?.street_2?.trim()) {
        address.push(eventData?.location?.street_2?.trim());
      }
      if (eventData?.location?.city && eventData?.location?.city?.trim()) {
        address.push(eventData?.location?.city?.trim());
      }
      if (eventData?.location?.state && eventData?.location?.state?.trim()) {
        address.push(eventData?.location?.state?.trim());
      }
      if (eventData?.location?.country_name && eventData?.location?.country_name?.trim()) {
        address.push(eventData?.location?.country_name?.trim());
      }
      if (eventData?.location?.postal_code && eventData?.location?.postal_code?.trim()) {
        address.push(eventData?.location?.postal_code?.trim());
      }
      locationAddress = address.join(", ")
      locationAddress = locationAddress;
    }
    return locationAddress;

  }
}
