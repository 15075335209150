import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-pay-now-failed',
  templateUrl: './pay-now-failed.component.html',
  styleUrls: ['./pay-now-failed.component.css']
})
export class PayNowFailedComponent {
  whitelabel: any;
  orgid: any;
  showCampaignButton = false;
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(
      params => {
        this.whitelabel = params['whitelabel'];
        this.orgid = params['organizationid'];
       
        if (this.whitelabel == '0' && this.orgid) {
          this.showCampaignButton = true;
        }
      }
    );   

  }
  goBackToCampaigns() {
    let URL = environment.whitelable_domain_url;
    URL += "/home?organizationid=" + this.orgid;
    window.location.href = URL;
  }
}
