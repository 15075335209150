import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs';
import { GiveNowService } from 'src/app/_services/give-now.service';

@Component({
  selector: 'app-pay-now-order',
  templateUrl: './pay-now-order.component.html',
  styleUrls: ['./pay-now-order.component.css']
})
export class PayNowOrderComponent implements OnInit, OnDestroy {

  fatchedParams: any;
  type: any;
  orderId: any;
  date : any
  name : any;
  email: any;
  phone : any;
  address : any;
  receipt_Url : any;
  status : any;
  organization : any;
  orderDetails: any;
  constructor(private activatedRoute: ActivatedRoute, private giveNowService: GiveNowService, private router: Router ,private spinnerService: NgxSpinnerService){
    this.receipt_Url = this.router.getCurrentNavigation().extras?.state?.['Url'];
  }
    


  ngOnInit(){
    this.orderId = this.activatedRoute.snapshot.params['orderid'];
    this.storeData();

  }
  ngOnDestroy(): void {
    this.spinnerService.hide();
  }



  storeData() {
    this.spinnerService.show();
    this.giveNowService.getStoreOrderDetails(this.orderId).subscribe(res => {
      this.spinnerService.hide();
      this.orderDetails = res;


      let address: any = [];
      let shippingAddress = "";
      if (this.orderDetails?.address?.address) {
        if (this.orderDetails?.address?.address?.street_1 && this.orderDetails?.address?.address?.street_1?.trim()) {
          address.push(this.orderDetails?.address?.address?.street_1?.trim());
        }
        if (this.orderDetails?.address?.address?.street_2 && this.orderDetails?.address?.address?.street_2?.trim()) {
          address.push(this.orderDetails?.address?.address?.street_2?.trim());
        }
        if (this.orderDetails?.address?.address?.city && this.orderDetails?.address?.address?.city?.trim()) {
          address.push(this.orderDetails?.address?.address?.city?.trim());
        }
        if (this.orderDetails?.address?.address?.state && this.orderDetails?.address?.address?.state?.trim()) {
          address.push(this.orderDetails?.address?.address?.state?.trim());
        }
        if (this.orderDetails?.address?.address?.country_name && this.orderDetails?.address?.address?.country_name?.trim()) {
          address.push(this.orderDetails?.address?.address?.country_name?.trim());
        }
        if (this.orderDetails?.address?.address?.postal_code && this.orderDetails?.address?.address?.postal_code?.trim()) {
          address.push(this.orderDetails?.address?.address?.postal_code?.trim());
        }
        shippingAddress = address.join(", ")
        this.orderDetails.shippingAddress = shippingAddress;
      }
      //this.name = res.owner;
      //this.email = res.order.email;
      //this.phone = res.order.phone;
      //this.status = res.order.status_state;
      //// this.date = res.order_date
      //this.address = `${res.order.address.street_1} ${res.order.address.street_2} ${res.order.address.city} ${res.order.address.state} ${res.order.address.country_name} ${res.order.address.postal_code}, ${res.order.address.country_code}`;

    })
  }


}
