import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';


@Injectable()
export class ToastMessage {
  constructor() { }
  Success(Message: string, timer?: number) {
    Swal.fire({
      title: 'Success!',
      icon: 'success',
      text: Message,
      timer: timer ? timer : 2000,
      toast: true,
      position: 'top',
      showConfirmButton: false
    })

  }


  Error(Message: string, timer?: number) {

    Swal.fire({
      title: 'Error!',
      text: Message,
      timer: timer ? timer : 2000,
      icon: 'error',
      toast: true,
      position: 'top',
      showConfirmButton: false
    });
    
  }
  

  
}

