<!-- <div class="container">
  <div *ngIf="isLoading" class="text-centre">
    <div id="loader"></div>
    <div id="backdrop"></div>
  </div>
</div> -->

<!-- payment section -->
<div class="container-fluid min-vh-100 dec-value-card bg-new">
  <div class="card-details-main min-vh-100 py-4">
    <div class="card-details-container">
      <div class="row justify-content-between">
        <div class=" align-self-center col-8 col-lg-8 col-md-8 col-sm-7 top-header ">
          <div class="org-name">{{this.organizationName}}</div>

        </div>
        <div class="align-self-center col-3 col-lg-2 col-md-2 col-sm-2 col-xl-2 px-3 pb-1">
          <img class="" [src]="this.orgLogo">
        </div>
      </div>
      <div class="row justify-content-between">
        <ng-container *ngIf="isCampaign">
          <div class="textsize mb-2" style="font-weight: 700;font-size: 15px;">{{this.campaignName}}</div>
        </ng-container>
      </div>
      <div class="panel-body textsize">
        <form name="form" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
          <div formGroupName="card">

            <!-- name -->
            <div class="row mb-1 mb-lg-2 mb-md-2 mb-sm-2">
              <div class="form-group col-12">
               
                <ejs-textbox type="text" id="name_on_card" name="name_on_card" placeholder="Name on Card"
                  formControlName="name_on_card"
                  [ngClass]="{'is-invalid': f['name_on_card'].touched && f['name_on_card'].errors, 'is-valid': f['name_on_card'].valid}"
                  required></ejs-textbox>
                <!-- <input type="text" class="form-control" id="name_on_card" name="name_on_card" placeholder="Name on Card"
                  formControlName="name_on_card"
                  [ngClass]="{'is-invalid': f['name_on_card'].touched && f['name_on_card'].errors, 'is-valid': f['name_on_card'].valid}"
                  required /> -->
                <div class="px-2">
                  <small class="e-error"
                    *ngIf="f['name_on_card'].touched && f['name_on_card'].errors && f['name_on_card'].pristine">
                      Name is required
                  </small>
                </div>
              </div>
            </div>

            <!-- card details -->
            <div class="row form-group">
              <div class=" col-12 col-lg-6 col-md-12 col-sm-12 my-1 my-lg-2 my-md-2 my-sm-2">
                <div class="card-number e-input">
                  <input type="text" class="form-control" name="card_number" formControlName="card_number"
                    placeholder="0000 0000 0000 0000" autocomplete="cc-number" mask="0000 0000 0000 0000"
                    [ngClass]="{'is-invalid': f['card_number'].touched && f['card_number'].errors,'is-valid': f['card_number'].valid}"
                    required style="border-radius: 0%;" />

                  <div class="px-2">
                    <small class="e-error" *ngIf="f['card_number'].touched && f['card_number'].pristine">
                      Card number is required
                    </small>
                  </div>
                  <div class="px-2">
                    <small class="e-error"
                      *ngIf="f['card_number'].touched && f['card_number'].error && f['card_number'].dirty && f['card_number'].invalid">
                      Min 16 digits are required
                    </small>
                  </div>
                </div>
              </div>
              <!-- <div class="row-md row-sm"> -->
              <div class="col-4 col-lg-2 col-md-4 col-sm-4 my-1 my-lg-2 my-md-2 my-sm-2 ">
                <select class="form-select e-input px-3" formControlName="expiration_month" style="border-radius: 0%;"
                  (change)="expiryBugMonth($event.target )"
                  [ngClass]="{'is-invalid': f['expiration_month'].touched && f['expiration_month'].errors,'is-valid': f['expiration_month'].valid}">
                  <option selected disabled>MM</option>
                  <option class="" *ngFor="let month of months" [ngValue]="month.value" style="opacity: 0.6">
                    {{month.label}}
                  </option>
                </select>
              </div>
              <div class="col-4 col-lg-2 col-md-4 col-sm-4 my-1 my-lg-2 my-md-2 my-sm-2">
                <select class="form-select e-input px-2" formControlName="expiration_year" *ngIf="!this.redFlag" style="border-radius: 0%;"
                  [ngClass]="{'is-invalid': f['expiration_year'].touched && f['expiration_year'].errors,'is-valid': f['expiration_year'].valid}">
                  <option selected disabled>YYYY</option>
                  <option class="" *ngFor="let year of years" [ngValue]="year.value" style="opacity: 0.6">
                    {{year.label}}
                  </option>
                </select>
                <select class="form-select e-input px-2" formControlName="expiration_year" *ngIf="this.redFlag"
                  [ngClass]="{'is-invalid': f['expiration_year'].touched && f['expiration_year'].errors,'is-valid': f['expiration_year'].valid}">
                  <option selected disabled>YYYY</option>
                  <option class="" *ngFor="let year of currentYear" [ngValue]="year.value" style="opacity: 0.6">
                    {{year.label}}
                  </option>
                </select>
              </div>
              <div class="col-4 col-lg-2 col-md-4 col-sm-4 my-1 my-lg-2 my-md-2 my-sm-2">
                <input type="number" class="form-control e-input px-2" pattern="[0-9]+" name="cardCVC" maxlength="4" style="border-radius: 0%;"
                  placeholder="CVV" autocomplete="cc-csc" formControlName="cvv"
                  [ngClass]="{ 'is-invalid': f['cvv'].touched && f['cvv'].errors,'is-valid': f['cvv'].valid}"
                  required />
                <div class="" *ngIf="f['cvv'].touched && f['cvv'].pristine">
                  <small class="e-error">
                    CVV is required
                  </small>
                </div>
                <div class="" *ngIf="f['cvv'].touched && f['cvv'].errors && f['cvv'].dirty && f['cvv'].invalid">
                  <small class="e-error">
                    CVV is invalid
                  </small>
                </div>
              </div>
              <!-- </div> -->
            </div>

            <!-- Email and Zip -->
            <div class="">
              <div class="form-group row">
                <div class="col-12 col-lg-9 col-md-9 col-sm-12 my-1 my-lg-2 my-md-2 my-sm-2">
                  <ng-container *ngIf="this.QR_info_data?.intake_rules?.require_email">
                    <ejs-textbox type="email" class="form-control" name="email" placeholder="Email" formControlName="email"
                      [ngClass]="{ 'is-invalid': f['email'].touched && f['email'].errors,'is-valid': f['email'].valid}"
                      required></ejs-textbox>
                      
                    <!-- <input type="email" class="form-control" name="email" placeholder="Email" formControlName="email"
                      [ngClass]="{ 'is-invalid': f['email'].touched && f['email'].errors,'is-valid': f['email'].valid}"
                      required /> -->
                    <div class="px-1">
                      <small class="e-error"
                        *ngIf="f['email'].errors && f['email'].touched && f['email'].invalid && f['email'].dirty">
                        Please enter valid Email address
                      </small>
                    </div>
                    <div class="px-1">
                      <small class="e-error" *ngIf="f['email'].touched && f['email'].pristine">
                        Email is required
                      </small>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!this.QR_info_data?.intake_rules?.require_email">
                    <ejs-textbox type="email" class="form-control" name="email" placeholder="Email" formControlName="email"></ejs-textbox>
                    <!-- <input type="email" class="form-control" name="email" placeholder="Email" formControlName="email" /> -->
                  </ng-container>
                </div>

                <div class="col-12 col-lg-3 col-md-3 col-sm-12 my-1 my-lg-2 my-md-2 my-sm-2">
                  <ejs-textbox type="text" class="form-control" name="zip" placeholder="Zip" formControlName="zip"
                    [ngClass]="{'is-invalid': f['zip'].errors, 'is-valid': f['zip'].touched && f['zip'].valid}"></ejs-textbox>
                    
                  <!-- <input type="text" class="form-control" name="zip" placeholder="Zip" formControlName="zip"
                    [ngClass]="{'is-invalid': f['zip'].errors, 'is-valid': f['zip'].touched && f['zip'].valid}" /> -->
                </div>
              </div>
            </div>

          </div>

          <!-- Amount input -->
          <div class="form-group resp mb-0 mb-lg-2 mb-xl-2 mb-sm-2 mb-md-2">
            <div class="row align-items-center">
              <div class="col-6 col-lg-2 col-md-2 col-sm-8 my-1 my-lg-2 my-md-2 my-sm-2 ">
                <div class="">
                  Donation Amount
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-3 col-sm-4 my-1 my-lg-2 my-md-2 my-sm-2">
                <div class="new-chat-window">
                  <i class="fa dollar">$</i>
                  <input type="number" class="form-control e-input new-chat-window-input px-4" id="new-chat-window-input"
                    name="amount" placeholder="0.00" formControlName="amount" style="border-radius: 0%;"
                    [ngClass]="{'is-invalid': a['amount'].errors && a['amount'].touched,'is-valid': a['amount'].valid }" />
                </div>
                <div class="px-2">
                  <small class="e-error" *ngIf="a['amount'].touched && a['amount'].pristine">
                    Amount is required
                  </small>
                </div>
                <div class="px-2">
                  <small class="e-error"
                    *ngIf="a['amount'].errors && a['amount'].touched && a['amount'].invalid && a['amount'].dirty">
                    Max amount 999.99
                  </small>
                </div>
              </div>
              <div
                class="col-12 col-lg-7 col-md-7 col-sm-12 my-1 my-lg-2 my-md-2 my-sm-2 d-flex gap-1 align-items-center">
                <div class="justify-content-start">
                  <input type="checkbox" class="px-0 align-text-bottom" name="nosharegiving" placeholder=""
                    formControlName="no_share_giving" />
                </div>
                <div style="font-size: 11px;">Don't display my name publicly on the fundraiser</div>
                <div class="pb-1"><img class="about" src="../../assets/images/about.png" width="17" height="17">
                </div>
              </div>
            </div>

          </div>


          <!-- progress bar -->
          <div *ngIf="this.QR_info_data?.intake_rules?.require_tips">
            <!-- <div style="background-color: #6dd162; height: 2px;"></div> -->
            <div class="progress-bar-new"></div>
            <div>

              <div class="d-flex justify-content-center">
                GiveAnywhere Platform Tip
              </div>
              <div class="d-flex justify-content-center">
                {{this.paymentForm.value.tip_amount}}%
              </div>
              <div class="row justify-content-center gap-2 ">
                <div class="col-1">0%</div>
                <div class=" align-self-center col-7 col-lg-9 col-md-9 col-sm-9 col-xl-9">
                  <!-- <div class="progress">
                    <div class="progress-bar" style="width: 50%; background-color: #6dd162;" role="progressbar"
                      aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> -->
                  <input type="range" class="form-range e-input"  min="0" max="30" step="1" id="customRange2" #slider
                    (change)="setTip($event.target)" formControlName="tip_amount">
                </div>
                <div class="col-1">30%</div>
              </div>
              <!-- [value]="this.paymentForm.value.tip_amount" -->

              <div class="d-flex justify-content-center textsize1">
                GiveAnywhere does not charge nonprofits and cause organizers to process donations.
              </div>
              <div class="d-flex justify-content-center pb-0 pb-xl-3 pb-lg-3 pb-md-3 pb-sm-3 textsize1">
                The Platform is supported by the generosity of donors like you.
              </div>
            </div>
          </div>

          <!-- calculations -->
          <div>
            <!-- <div style="background-color: #6dd162; height: 2px;"></div> -->
            <div class="progress-bar-new"></div>

            <div class="d-flex justify-content-center new-text">
              Your Donation
            </div>
            <div class="row justify-content-center p-1 textsize">
              <div class="col-4">
                Donation
              </div>

              <div class="col-3 px-5 textsize" *ngIf="!this.paymentForm.value.amount">
                $0.00
              </div>
              <div class="col-3 px-5 textsize" *ngIf="this.paymentForm.value.amount > 0">
                ${{this.paymentForm.value.amount | number: '1.2-2' }}
              </div>
            </div>
            <div class="row justify-content-center p-1 textsize" *ngIf="this.QR_info_data?.intake_rules?.require_tips">
              <div class="col-4">
                GiveAnywhere Tip
              </div>
              <div class="col-3 px-5 textsize">
                ${{this.calculateTip() | number: '1.2-2'}}
              </div>
            </div>
            <div class="row justify-content-center p-1 textsize">
              <div class="col-4">
                Total
              </div>
              <div class="col-3 px-5 textsize" *ngIf="this.QR_info_data?.intake_rules?.require_tips">
                ${{this.calculateTotalAmtForTip() | number: '1.2-2'}}
              </div>
              <div class="col-3 px-5 textsize" *ngIf="!this.QR_info_data?.intake_rules?.require_tips">
                ${{this.calculateTotalAmt() | number: '1.2-2'}}
              </div>
            </div>
          </div>

          <!-- button -->

          <div class="d-flex mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3 mt-0">
            <button class="btn pay-now e-btn" type="submit" [disabled]="!this.paymentForm.valid">
              Give Now
            </button>
          </div>
          <ngx-spinner type="ball-scale-multiple"></ngx-spinner>

        </form>
      </div>
    </div>
  </div>
</div>