import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pay-now-success',
  templateUrl: './pay-now-success.component.html',
  styleUrls: ['./pay-now-success.component.css']
})
export class PayNowSuccessComponent {
  whitelabel: any;
  orgid: any;
  campaignid: any;
  whitelable_domain_url= environment.whitelable_domain_url;
  versions = [
    {
      width: '44px',
      height: '10px',
      color: '#548235',
      marginBottom: '15px',
      marginLeft: '10px',
    }
  ];
  emailId: any;
  actionId: any;
  qrcode_Id: any;
  showCampaignButton = false;

  constructor(private activatedRoute:ActivatedRoute){
    this.activatedRoute.params.subscribe(
      params => {
        this.whitelabel = params['whitelabel'];
        this.orgid = params['organizationid'];
        this.campaignid = params['campaignid'];
        this.emailId = params['emailid'];
        this.actionId = params['actionid'];
        this.qrcode_Id = params['qrcode_id'];
        if (this.whitelabel == '0' && this.orgid) {
          this.showCampaignButton = true;
        }
      }
    );
    if(this.campaignid){
      this.redirectToindividualdonor();
    }
    
  }


  redirectToindividualdonor(){
    let URL = environment.website_domain
    let redirectURL = `https://` + URL + `.com/individualdashboard`;
    if (this.emailId && this.actionId) {
      redirectURL += "&emailid=" + this.emailId + "&actionid=" + this.actionId + "&qrcode_id=" + this.qrcode_Id;
    }
    setTimeout(() => {
      window.location.href = redirectURL;
      window.open(redirectURL)
    }, 5000);
  }
  goBackToCampaigns() {
    let URL = environment.whitelable_domain_url;
    URL += "/home?organizationid=" + this.orgid;
    window.location.href = URL;
  }
}
