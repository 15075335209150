<div class="afterPaymentPage">
  <div class="container-80 body">
    <div class="card">
      <div class="icon-block-div">
        <i class="checkmark">🎊</i>
      </div>
      <h1 *ngIf="!whitelabel">Payment Done</h1>
      <h1 *ngIf="whitelabel">Success</h1>
      <p>
        We have processed your payment successfully.
        <br> Thank you!
      </p>
      <a *ngIf="whitelabel && orgid" (click)="goBackToCampaigns();" class="btn d-inline-block mt-3 lastPageBack_camp btn-light">Back to Campaigns</a>


      <div *ngIf="whitelabel && campaignid" class="redirect-box">
        <div class="redirecting-text d-flex justify-content-center">
          <h2>Redirecting to other ways to help </h2>
          <div class="d-flex align-items-end">
            <span class="pulse-loader" style="width: 44px; height: 10px; margin-bottom: 15px; margin-left: 10px; color: rgb(84, 130, 53);">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
        <h3 class="sm-information-txt">Recurring Donations - Sponsorship - Social Sharing</h3>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container-80 body">
  <div class="card">
    <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
      <!-- <i class="checkmark">✓</i>
      <i class="checkmark">&#10003;</i>
    </div>
    <h1>Success</h1>
    <p>We have processed your payment successfully.<br /> Thank you!</p>
    <div *ngIf="whitelabel" style="margin-top: 40px;
        border-top: 2px solid #9ebf6c;">
        <div class="d-flex justify-content-center">
          <h2>Redirecting to other ways to help </h2>
          <div class="d-flex align-items-end">
            <span *ngFor="let version of versions" class="pulse-loader"
            [style.width]="version.width" [style.height]="version.height" [style.marginBottom]="version.marginBottom" [style.marginLeft]="version.marginLeft" [style.color]="version.color">
            <span></span>
            <span></span>
            <span></span>
          </span>
          </div>
        </div>
      <h3>Recurring Donations - Sponsorship - Social Sharing</h3>
      // Comment start
      <!--<div class="d-flex justify-content-evenly">
            <button class="btn btn-success border-0 px-5" style="background-image: linear-gradient(to bottom,#548235, #88b04b)" (click)="redirectToWhiteLable()">Yes</button>
            <button class="btn btn-success border-0 px-5" style="background-image: linear-gradient(to bottom, #548235, #88b04b)">No</button>
          </div>
      // Comment end
    </div>
  </div>
</div> -->
