<div class="container mt-5">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  <div class="card order mt-1 ">
    <div class="card-header">
      <h5 class="mb-0 heading">Order Details</h5>
    </div>
    <div class="card-body">
      <div class="form-success row mt-20">
        <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
          <div class="card h-100  content order">
            <div class="card-body">
              <div class="user-details">
                <div class="row tracking-details my-2">
                  <div class="col-4"><Strong>Order ID</Strong></div>
                  <div class="col-8">{{orderDetails?.storefront_order_id}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.order_date">
                  <div class="col-4"><Strong>Date</Strong></div>
                  <div class="col-8">{{orderDetails?.order_date | date : 'MM/dd/yyyy'}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.organization && orderDetails?.organization.trim()">
                  <div class="col-4"><Strong>Organization</Strong></div>
                  <div class="col-8">{{orderDetails?.organization}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.status_state">
                  <div class="col-4"><Strong>Status</Strong></div>
                  <div class="col-8">{{orderDetails?.status_state}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.address?.name && orderDetails?.address?.name.trim()">
                  <div class="col-4"><Strong>Name</Strong></div>
                  <div class="col-8">{{orderDetails?.address?.name}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.email && orderDetails?.email.trim()">
                  <div class="col-4"><Strong>Email</Strong></div>
                  <div class="col-8">{{orderDetails?.email}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.phone && orderDetails?.phone.trim()">
                  <div class="col-4"><Strong>Phone</Strong></div>
                  <div class="col-8"><Strong></Strong>{{orderDetails?.phone}}</div>
                </div>
                <div class="row tracking-details" *ngIf="orderDetails?.shippingAddress">
                  <div class="col-4"><Strong>Shipping Address</Strong></div>
                  <div class="col-8">{{orderDetails?.shippingAddress}}</div>
                </div>
                <div class="row tracking-details mt-2"  *ngIf="orderDetails">
                  <div class="col-sm-12">
                    <p>Your receipt has been emailed to you. If there was an issue sending receipt you can return to the store and find your order with the order ID.</p>
                  </div>
                </div>
              </div>
              <!--<div class="button text-center" *ngIf="orderDetails && receipt_Url">-->
                <!-- <a class="btn btn-primary" [href]="receipt_Url">Download Copy of Order</a> -->
                <!--<a class="e-btn e-primary" target="_blank" [href]="receipt_Url">Download Copy of Order</a>
              </div>-->
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
          <!--<div class="heading text-center"><h3>Order Details</h3></div>-->
          <div class="card content  p-0 h-100 order">
            <div class="card-header">
              <h5 class="mb-0 heading">Item Details</h5>
            </div>
            <div class="card-body">
              <div class="table-responsive" *ngIf="orderDetails?.order_items?.length > 0">
                <table class="table  table-bordered  ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th scope="col">Item </th>
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let orderItem of orderDetails?.order_items; let i=index">
                      <td>{{i + 1}}</td>
                      <td>
                        <strong>{{orderItem.name}}</strong>
                        <div *ngFor="let attribute of orderItem?.attribute_fields">
                          <small>{{attribute.key}} : {{attribute.value}}</small>
                        </div>
                      </td>
                      <td>{{orderItem.quantity}}</td>
                      <td>{{orderItem.price | currency:'USD'}}</td>
                      <td>{{orderItem.amount | currency:'USD'}}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th colspan="4" class="text-end">Total</th>
                      <th>{{orderDetails?.total_amount | currency:'USD'}}</th>
                    </tr>
                  </thead>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!--<div class="container-80 body">
    <div class="card">

      <div class="form-success row col-12">

      <div class="col-lg-6 col-md-6 col-sm-12 details">
        <div class="row content">
          <div class="col-lg-5 col-md-12"><h5>Order ID</h5></div>
          <div class="col-lg-7 col-md-12">{{orderId}}</div>
        </div>
        <div class="row content">
          <div class="col-lg-5 col-md-12"><h5>Date</h5></div>
          <div class="col-lg-7 col-md-12">{{date}}</div>
        </div>
        <div class="row content">
          <div class="col-lg-5 col-md-12"><h5>Organization</h5></div>
          <div class="col-lg-7 col-md-12">{{organization}}</div>
        </div>
        <div class="row content">
          <div class="col-lg-5 col-md-12"><h5>Status</h5></div>
          <div class="col-lg-7 col-md-12">{{status}}</div>
        </div>

        <div class="card user content my-2">
          <div class="user-details">
            <div class="row tracking-details my-2">
              <div class="col-4"><Strong>Name:</Strong></div>
              <div class="col-8">{{name}}</div>
            </div>
            <div class="row tracking-details my-2">
              <div class="col-4"><Strong>Email:</Strong></div>
              <div class="col-8">{{email}}</div>
            </div>
            <div class="row tracking-details my-2">
              <div class="col-4"><Strong>Phone:</Strong></div>
              <div class="col-8"><Strong></Strong>{{phone}}</div>
            </div>
            <div class="row tracking-details">
              <div class="col-4"><Strong>Shipping Address:</Strong></div>
              <div class="col-8">{{address}}</div>
            </div>
          </div>

        </div>

        <div class="content-description my-3">
          <div class="">Your receipt has been emailed to you. If there was an issue sending receipt you can return to the store and find your order with the order ID.</div>
        </div>

        <div class="button col-lg-12 col-md-12 col-sm-12 my-2">-->
          <!-- <a class="btn btn-primary" [href]="receipt_Url">Download Copy of Order</a> -->
          <!--<a class="e-btn e-primary" [href]="receipt_Url">Download Copy of Order</a>
        </div>




      </div>


      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="heading my-5"><h3>Order Details</h3></div>
        <div class="card order-details"></div>



      </div>
    </div>

    </div>

  </div>-->
