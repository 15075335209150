import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PayNowComponent } from './pay-now/pay-now.component';
import { PayNowSuccessComponent } from './pay-now/pay-now-success/pay-now-success.component';
import { PayNowFailedComponent } from './pay-now/pay-now-failed/pay-now-failed.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask-2'
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { urlSerializer } from './_helpers/url-serializer';
import { PayNowStripeComponent } from './pay-now/pay-now-stripe/pay-now-stripe.component';
import { ToastMessage } from './common/commonfunction';
import { PositiveNumericDirective } from './_directives/positive-numeric.directive';
import { PayNowStoreComponent } from './pay-now/pay-now-store/pay-now-store.component';
import { PayNowOrderComponent } from './pay-now/pay-now-order/pay-now-order.component';
import { CheckBoxModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule, MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { PayNowEventComponent } from './pay-now/events/pay-now-event/pay-now-event.component';
import { EventOrderComponent } from './pay-now/events/event-order/event-order.component';
import { PayNowRaffleComponent } from './pay-now/raffle/pay-now-raffle/pay-now-raffle.component';
import { PayNowOpenauctionComponent } from './pay-now/auction/pay-now-openauction/pay-now-openauction.component';
import { PayNowPledgeComponent } from './pay-now/pay-now-pledge/pay-now-pledge.component';

@NgModule({
  declarations: [
    AppComponent,
    PayNowComponent,
    PayNowSuccessComponent,
    PayNowFailedComponent,
    PayNowStripeComponent,
    PositiveNumericDirective,
    PayNowStoreComponent,
    PayNowOrderComponent,
    PayNowEventComponent,
    EventOrderComponent,
    PayNowRaffleComponent,
    PayNowOpenauctionComponent,
    PayNowPledgeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    RadioButtonModule,
    TextBoxModule,
    DropDownListModule,
    MaskedTextBoxModule,
    CheckBoxModule,
  ],
  providers: [
    { provide: UrlSerializer, useClass: urlSerializer },
    ToastMessage,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
