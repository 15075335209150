import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Stripe, StripeElements, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement, loadStripe, StripeCardNumberElementOptions, StripeCardExpiryElementOptions, StripeCardCvcElementOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { GiveNowService } from 'src/app/_services/give-now.service';
import { ToastMessage } from 'src/app/common/commonfunction';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pay-now-pledge',
  templateUrl: './pay-now-pledge.component.html',
  styleUrls: ['./pay-now-pledge.component.css']
})
export class PayNowPledgeComponent  implements OnInit {

  payerAmountValue:boolean = true
  pledgePaymentForm: FormGroup;
  submitted: boolean = false;
  years: any = [];
  months: any = [];
  currentMonth: any = [];
  currentYear: any = [];
  thisMonth: number
  thisYear: number
  rangeValue: number
  calculatedTipAmount: number = 0;
  donationAmount: number;
  fatchedParams: any;
  organizationName: string;
  sourceId: string = ""
  ParamsArray: string[] = []
  QRData: any;
  QR_info_data: any;
  orgLogo: string;
  redFlag: boolean = false;
  campaignName: string = "";
  isCampaign: boolean = false;
  private stripe: Stripe;
  stripeSetupData: any;
  private elements: StripeElements;
  private cardNumber: StripeCardNumberElement;
  private cardExpiry: StripeCardExpiryElement;
  private cardCvc: StripeCardCvcElement;
  stripeCardNumberErrorMessage;
  stripeExpiryErrorMessage;
  stripeCVCErrorMessage;
  sponsorName: string;
  accountId: string;
  paymentSourceData;
  cardValidatorsData: any;
  orgid: any;
  whitelabel: any;
  isTrackingCode: boolean = false;
  trackingDetails: any;
  address: any;
  shipping_amount: any;
  tax_amount: any;
  owner: any;
  status_state: any;
  order_id: any;
  phone: any;
  small_logo_path: any;
  arraytracking: any = ["test", "test02", "test03", "test04", "test05", "test06", "test07"];
  type: any;
  orderId: any;
  account_Id: any;
  organization_id: any;
  orderDetails: any;
  Amount: any;
  emailId: any;
  actionId: any;
  qrcode_Id: any;
  organizationId: string;
  pledgeId: string;
  donationButton:false;
  contactid: any;
  constructor(private giveNowService: GiveNowService, private activatedRoute: ActivatedRoute, private route: Router, private spinnerService: NgxSpinnerService, private toastMessage: ToastMessage, ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.fatchedParams = params
      this.type = params['type'];
      const data = params['data'];
      if (data) {
        this.extractAmount(data);
      }

      // if (params?.sponsor) {
      //   this.sponsorName = params?.sponsor;
      // }
      // this.orderId = params['orderid']
      if (params?.payeraccountid) {
       this.accountId = params?.payeraccountid;
      }
      this.emailId = params['emailid'];
      this.actionId = params['actionid'];
      this.qrcode_Id = params['qrcode_id'];
    })
    this.setCardControlsValidators();
  }
  amount: any;
  extractAmount(data: string) {
    const segments = data.split(':');
    this.organizationId = segments[1]
    this.pledgeId = segments[2]
    this.amount = segments[3]
    this.amount = parseFloat(this.amount)
  }
  setCardControlsValidators() {
    this.cardValidatorsData = {
      name_on_card: [Validators.required],
      zip: [Validators.pattern("^\\d{5}(-{0,1}\\d{4})?$"), Validators.minLength(5), Validators.maxLength(11)]
    };

  }

  ngOnInit() {

    this.initializeStripe();
    this.getOrganizationPledgeinfo(this.contactid)
    this.pledgePaymentForm = new FormGroup({
      account_id: new FormControl(""),
      transaction_type: new FormControl(10000),
      transaction_type_id: new FormControl(this.pledgeId),
      card: new FormGroup({
        email: new FormControl(""),
        name_on_card: new FormControl("", this.cardValidatorsData.name_on_card),
        zip: new FormControl("", this.cardValidatorsData.zip),
      }),
      amount: new FormControl([Validators.required, Validators.min(1)]),
      tip_amount: new FormControl(0),
      no_share_giving: new FormControl(false),
      allow_payer_cover_amount: new FormControl(true),
      payer_cover_amount: new FormControl(0),
      sponsor_username: new FormControl(this.sponsorName),
      existing_payment_source: new FormControl(false),
      payment_source_id: new FormControl(""),
      future_use: new FormControl(false),
      payer_account_id: new FormControl(""),
      key_values: new FormControl([]),
      qrcode_type: new FormControl("pledge"),
      qrcode_id: new FormControl(this.pledgeId)
    });
    this.checkAPI();

  }


  get f() {
    return this.pledgePaymentForm['controls']['card']['controls']
  }
  get a() {
    return this.pledgePaymentForm['controls']
  }
  
  private async initializeStripe() {
    let publishableKey = environment.stripe.publicKey;
    this.stripe = await loadStripe(publishableKey);
    this.elements = this.stripe.elements();
    this.loadStripeElement();
  }

  getOrganizationPledgeinfo(contact_id:any) {
    this.spinnerService.show();
    this.giveNowService.GetPledgeDonationinfo(this.organizationId,contact_id,this.pledgeId).subscribe((res: any) => {
      if(this.accountId !== ''){
        this.pledgePaymentForm.patchValue({
          card: { email: res.owner_email },
          amount: this.amount
        })
      }else{
        this.pledgePaymentForm.patchValue({
          card: { email: res.owner_email },
          amount: res.pledge.pledge_amount
        })
      }
      this.owner = res.owner;
      this.pledgePaymentForm.value.email = res.email;
      this.small_logo_path = res.small_logo_path && res.small_logo_path?.trim() ? res.small_logo_path.trim() : null;
      this.organizationName = res.owner;
      this.organization_id = res.owner_id;
      if (res?.pledge?.donor_contact_id && res?.pledge?.donor_contact_id?.trim()) {
        this.getPaymentSources(res?.pledge?.donor_contact_id);
      }
      else {
        //this.spinnerService.hide();
        this.getPayerCoverAmount();
      }
      
    },
      error => {
        this.spinnerService.hide();
        // this.handleErrors.HandleError(<any>error);
      }
    );
  }
  
  checkAPI() {
    if (this.fatchedParams) {
      let textData = this.fatchedParams.data;
    }
  }

  getPaymentSources(accountId: any) {
    this.giveNowService.getWallet(accountId).subscribe(
      (res: any) => {
        this.spinnerService.hide();
        if (!res) {
          this.pledgePaymentForm.controls["payer_account_id"].setValue(null);
          return;
        }
        this.pledgePaymentForm.controls["payer_account_id"].setValue(accountId);
        this.paymentSourceData = res;
        if (res && res.charge_cards && res.charge_cards.length > 0) {

          this.paymentSourceData.charge_cards = this.paymentSourceData?.charge_cards.filter(b => {
            return b.status_state?.toLocaleLowerCase() == 'ready'
          });

          if (this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0) {
            this.pledgePaymentForm.controls["existing_payment_source"].setValue(true);
            this.selectExistingPaymentChange();
            return;
          }
        }
      },
      error => {
        // handle error
        this.pledgePaymentForm.controls["payer_account_id"].setValue(null);
        this.spinnerService.hide();
      },
      () => {
        this.getPayerCoverAmount();
      }
    );
  }

  setTip(data: any) {
    this.pledgePaymentForm.patchValue({
      tip_amount: Number(this.calculateTip())
    })
  }

  calculateTip() {
    let donationAmount = this.pledgePaymentForm.value.amount
    this.donationAmount = donationAmount

    let tip_percent = this.pledgePaymentForm.value.tip_percent

    this.calculatedTipAmount = donationAmount * ((tip_percent) / 100)
    return (this.calculatedTipAmount)
  }

  onSubmit() {
    this.submitted = true;
    if (this.pledgePaymentForm.controls['allow_payer_cover_amount'].value) {
      this.pledgePaymentForm.controls['payer_cover_amount'].setValue(this.calculateGiftFees()?.toFixed(2))
    } else {
      this.pledgePaymentForm.controls['payer_cover_amount'].setValue(0.00)
    }

    if (this.pledgePaymentForm.value.tracking_code != "Select Code" && this.pledgePaymentForm.value.tracking_code != "") {
      let selectedValue = this.arraytracking.find(x => x.code === this.pledgePaymentForm.value.tracking_code);
      this.pledgePaymentForm.patchValue({
        tracking_code: this.pledgePaymentForm.value.tracking_code,
        key_values: [{ trackingCode: selectedValue.label, value: selectedValue.code }]
      })
    }
    this.pledgePaymentForm.patchValue({
      amount: Number(this.pledgePaymentForm.value.amount),
      account_id: this.organizationId
      // tip_amount: Number(this.calculatedTipAmount?.toFixed(2))
    })
    if (this.pledgePaymentForm.invalid) {
      for (let inner in this.pledgePaymentForm.controls) {
        this.pledgePaymentForm.get(inner).markAsTouched();
        this.pledgePaymentForm.get(inner).markAsDirty();
      }
      return;
    }
    let postData: any = { ...this.pledgePaymentForm.value };
    if (postData.existing_payment_source) {
      postData.payment_source_type = "charge_card";
      postData.card = null;
      this.spinnerService.show();
      this.postGiveNow(postData);
    }
    else {
      this.createPaymentMethodAndPostData();
    }
  }

  calculateTotalAmt() {
    let total = Number(this.pledgePaymentForm.value.amount) + this.calculateGiftFees()
    return total
  }


  giftFeesAmt() {
    let total = 0.00
    if (this.payerCoverAmountData?.per_transaction_percent > 0) {
      total = parseFloat(this.pledgePaymentForm.value.amount) * this.payerCoverAmountData?.per_transaction_percent / 100
    }
    if (this.payerCoverAmountData?.per_transaction_amount > 0) {
      if (total == 0) {
        total = parseFloat(this.pledgePaymentForm.value.amount);
      }
      total = total + this.payerCoverAmountData?.per_transaction_amount
    }
    return total
  }

  calculateGiftFees() {
    if (this.pledgePaymentForm.controls['allow_payer_cover_amount'].value) {
      //let total = (Number(this.storePaymentForm.value.amount) * 0.05) + (this.storePaymentForm.value.amount > 0 ? 0.25 : 0.00)
      //return total
      return this.giftFeesAmt();
    }
    else {
      //let total = (Number(this.storePaymentForm.value.amount) * 0.00) + (this.storePaymentForm.value.amount > 0 ? 0.00 : 0.00)
      //return total
      return 0;
    }
  }

  postGiveNow(postData: any) {
    let accountid = postData.account_id
    let campaignid = postData.transaction_type_id
    if(this.emailId && this.actionId){
      this.updateActionTracking(this.emailId, this.actionId, accountid, postData);
    }
    this.giveNowService.postPaymentDetails(postData).subscribe(res => {
      if (res.status == "succeeded") {
        this.spinnerService.hide();
        let id = 1;

        if (this.whitelabel == '0') {
          this.route.navigate(['paysuccess']);
        } else {
          this.route.navigate(['paysuccess', id, accountid, campaignid]);
        }
      }
      else if (res.status == "requires_action") {
        this.stripe.handleCardAction(res.details.payment_intent_client_secret).then((result) => {
          if (result.error) {
            this.toastMessage.Error(result.error.message);
            // redirect to error payment failed
            this.spinnerService.hide();
          } else {
            // call the server;
            postData.payment_intent_id = res.details.payment_intent_id;
            this.postGiveNow(postData);
          }

        })
      }
      else {
        this.spinnerService.hide();
        this.route.navigate(['payfailed']);
      }
    },
      error => {
        this.route.navigate(['payfailed']);
        this.spinnerService.hide();

      });
  }

  createPaymentMethodAndPostData() {
    this.spinnerService.show();
    const cardHolderName = this.pledgePaymentForm.value.card.name_on_card;
    let paymentMethodParams: any = {
      type: 'card',
      card: this.cardNumber,
      billing_details: {
        name: cardHolderName,
        email: this.pledgePaymentForm.value.card.email,
      }
    }

    if (this.pledgePaymentForm.value.card.zip && this.pledgePaymentForm.value.card.zip.trim()) {
      paymentMethodParams.billing_details.address = {
        postal_code: this.pledgePaymentForm.value.card.zip.trim()
      }
    }
    if (this.pledgePaymentForm.value.card.email && this.pledgePaymentForm.value.card.email.trim()) {
      paymentMethodParams.billing_details.email = this.pledgePaymentForm.value.card.email.trim()
    }
    this.stripe.createPaymentMethod(paymentMethodParams)
      .then((result) => {
        if (result.error) {
          this.spinnerService.hide();
          this.toastMessage.Error(result.error.message, 3000);
          return;
        }
        let postData: any = { ...this.pledgePaymentForm.value };
        postData.payment_source_id = result.paymentMethod.id;
        postData.payment_source_type = "charge_card";

        postData.payer_name = this.pledgePaymentForm.value.card.name_on_card;
        postData.payer_email = this.pledgePaymentForm.value.card.email;
        postData.payer_zip = this.pledgePaymentForm.value.card.zip;
        postData.card = null;
        //postData.qrcode_type = "ticket";
        //postData.qrcode_id = this.orderId;
        this.postGiveNow(postData);

      }).catch(error => {
        this.spinnerService.hide();

      });
  }

  selectExistingPaymentChange() {
    timer(200).subscribe(() => {
      this.pledgePaymentForm.controls["payment_source_id"].clearValidators();
      this.pledgePaymentForm.controls["payment_source_id"].reset();
      this.pledgePaymentForm.controls["payment_source_id"].setValue("");
      if (this.pledgePaymentForm.value.future_use) {
        this.pledgePaymentForm.controls["future_use"].setValue(false);
      }

      if (this.pledgePaymentForm.value.existing_payment_source) {
        this.pledgePaymentForm.controls["payment_source_id"].addValidators(Validators.required);
        let cardForm = this.pledgePaymentForm['controls']['card'] as FormGroup;
        cardForm.get('name_on_card').clearValidators();
        cardForm.get('name_on_card').updateValueAndValidity();
        cardForm.get('zip').clearValidators();
        cardForm.get('zip').updateValueAndValidity();
        cardForm.get('email').clearValidators();
        cardForm.get('email').updateValueAndValidity();
      }
      else {
        //this.pledgePaymentForm.controls["payment_source_id"].reset();


        this.resetCardControls();
        timer(500).subscribe(() => {
          this.elements = this.stripe.elements();
          this.loadStripeElement();
        });

      }
      this.pledgePaymentForm.controls['payment_source_id'].updateValueAndValidity();
    });


  }


  resetCardControls() {
    this.stripeCardNumberErrorMessage = null;
    this.stripeExpiryErrorMessage = null;
    this.stripeCVCErrorMessage = null;
    let cardForm = this.pledgePaymentForm['controls']['card'] as FormGroup;
    cardForm.markAsUntouched();
    cardForm.reset();
    cardForm.get('name_on_card').addValidators(this.cardValidatorsData.name_on_card);
    cardForm.get('zip').addValidators(this.cardValidatorsData.zip);
    if (this.QR_info_data?.intake_rules?.require_email && this.cardValidatorsData.email) {
      cardForm.get('email').setValidators(this.cardValidatorsData.email)
    }
  }

  loadStripeElement() {
    if (this.cardNumber) {
      this.cardNumber.unmount();
      this.cardExpiry.unmount();
      this.cardCvc.unmount();
    }
    this.mountCardNumberElement();
    this.mountCardExpiryElement();
    this.mountCardCVCElement();
  }

  private mountCardNumberElement() {
    const cardNumberOptions: StripeCardNumberElementOptions = {
      showIcon: true,
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,
      iconStyle: 'solid',
    };

    // card number
    this.cardNumber = this.elements.create('cardNumber', cardNumberOptions);
    this.cardNumber.mount('#card-number-element');
    this.cardNumber.on('change', event => {
      if (event.complete) {
        this.stripeCardNumberErrorMessage = null;
      } else if (event.error) {
        this.stripeCardNumberErrorMessage = event.error.message;
      }
    });
  }

  mountCardExpiryElement() {
    const cardExpiryOptions: StripeCardExpiryElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardExpiry = this.elements.create('cardExpiry', cardExpiryOptions);
    this.cardExpiry.mount('#card-exp-element');

    this.cardExpiry.on('change', event => {
      if (event.complete) {
        this.stripeExpiryErrorMessage = null;
      } else if (event.error) {
        this.stripeExpiryErrorMessage = event.error.message;
      }
    });
  }

  mountCardCVCElement() {
    const cardCVCOptions: StripeCardCvcElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardCvc = this.elements.create('cardCvc', cardCVCOptions);
    this.cardCvc.mount('#card-cvc-element');

    this.cardCvc.on('change', event => {
      if (event.complete) {
        this.stripeCVCErrorMessage = null;
      } else if (event.error) {
        this.stripeCVCErrorMessage = event.error.message;
      }
    });
  }

  getCardOptions() {
    let cardOptions: StripeCardNumberElementOptions = {
      classes: {
        base: 'form-control',
        invalid: 'is-invalid',
        complete: 'is-valid',
        focus: "form-control stripeelementfocus",
        empty: "form-control"
      },
      style: {
        base: {
          //iconColor: '#007bff',
          //color: '#495057',
          lineHeight: '1.7',
          //fontWeight: 300,
          //fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          //fontSize: '16px',
          '::placeholder': {
            color: 'rgba(33, 37, 41, 0.75)'
          },

        },
        invalid: {
          iconColor: '#dc3545',
          color: '#dc3545'
        },
      }
    }
    return cardOptions;
  }

  // redirectToWhiteLableOrder(pledgeId: any) {
  //   let URL = environment.whitelable_domain_url
  //   let redirectURL = URL + `/event-order/${pledgeId}?organizationid=${this.organizationId}`;
  //   if (this.emailId && this.actionId) {
  //     redirectURL += "&emailid=" + this.emailId + "&actionid=" + this.actionId + "&qrcode_id=" + this.qrcode_Id;;
  //   }
  //   window.location.href=redirectURL;
  // }

  updateActionTracking(emailId: string, actionId: string, accountId: any, postData: any){
    //  const totalAmountWithTip = this.calculateTotalAmtForTip();
    // const totalAmountWithFees = this.calculateTotalAmt();
    const donationAmount = postData.amount
    const payload = {
      account_id: accountId,
      email_id: emailId,
      action_id: actionId,
      action_tracking: {
        action_id: actionId,
        action_name: "Give Now",
        action_page: true,
        action_amount: donationAmount,
        action_outcome:"",
        campaign_id: this.qrcode_Id
      }
    };

    // Call your API service to update the API
    this.giveNowService.getActionTracking(emailId, payload).subscribe(
      (res: any) => {
        // console.log('API updated successfully:', res);
      },
      (error: any) => {
        console.error('Error updating API:', error);
      }
    );
  }

  payerCoverAmountData: any;

  getPayerCoverAmount() {
    this.spinnerService.show();
    this.giveNowService.getPayerCoverAmount(this.organization_id).subscribe(
      (res: any) => {
        this.payerCoverAmountData = res;
        this.spinnerService.hide();
      },
      error => {
        // handle error
        this.spinnerService.hide();
      },
      () => {
        //if (!this.payerCoverAmountData)
        //  this.payerCoverAmountData = {
        //    "per_transaction_amount": 0.25,
        //    "per_transaction_percent": 5.0
        //  }
      }
    );

  }
}

