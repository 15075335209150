<div class="container mt-5">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  <div class="card order mt-1 ">
    <div class="card-header">
      <h5 class="mb-0 heading">Order Details</h5>
    </div>
    <div class="card-body">
      <div class="form-success row mt-20">
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="card h-100  content order">
            <div class="card-body">
              <div class="user-details">
                <div class="row tracking-details my-2">
                  <div class="col-4"><Strong>Order ID</Strong></div>
                  <div class="col-8">{{orderDetails?.order?.ticket_order_id}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.order?.date">
                  <div class="col-4"><Strong>Date</Strong></div>
                  <div class="col-8">{{orderDetails?.order?.date | date : 'MM/dd/yyyy'}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.owner && orderDetails?.owner.trim()">
                  <div class="col-4"><Strong>Organization</Strong></div>
                  <div class="col-8">{{orderDetails?.owner}}</div>
                </div>
                <div class="row tracking-details my-2" *ngIf="orderDetails?.order?.status_state">
                  <div class="col-4"><Strong>Status</Strong></div>
                  <div class="col-8">{{orderDetails?.order?.status_state}}</div>
                </div>

                <div class="row tracking-details my-2" *ngIf="orderDetails?.order?.email && orderDetails?.order?.email.trim()">
                  <div class="col-4"><Strong>Email</Strong></div>
                  <div class="col-8">{{orderDetails?.order?.email}}</div>
                </div>
                <div class="row tracking-details mt-2" *ngIf="orderDetails">
                  <div class="col-sm-12">
                    <p>Your receipt has been emailed to you. If there was an issue sending receipt you can return to the event and find your order with the order ID.</p>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="button text-center" *ngIf="orderDetails && receipt_Url">-->
              <!-- <a class="btn btn-primary" [href]="receipt_Url">Download Copy of Order</a> -->
              <!--<a class="e-btn e-primary" target="_blank" [href]="receipt_Url">Download Copy of Order</a>
            </div>-->


          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
          <!--<div class="heading text-center"><h3>Order Details</h3></div>-->
          <div class="card content  p-0 h-100 order">
            <div class="card-header">
              <h5 class="mb-0 heading">Ticket Details</h5>
            </div>
            <div class="card-body">
              <div class="table-responsive" *ngIf="orderDetails?.purchased_items?.length > 0">
                <table class="table  table-bordered  ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th scope="col">Item </th>
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let orderItem of orderDetails?.purchased_items; let i=index">
                      <td>{{i + 1}}</td>
                      <td class="td-eventorderwidth">
                        <strong>{{orderItem?.ticket_event?.name}}</strong>
                        <div *ngIf="orderItem.ticket_type?.name">
                          <small>{{orderItem?.ticket_type?.name}}</small>
                        </div>
                        <div *ngIf="orderItem.price_category">
                          <small>{{orderItem.price_category?.name}}</small>
                        </div>
                        <div>
                          <small>{{orderItem?.ticket_event?.event_datetime |  date : 'MMMM dd yyyy'}} at {{orderItem?.ticket_event?.event_datetime |  date : 'hh:mm a'}}</small>
                        </div>
                        <div *ngIf="orderItem?.ticket_event?.location_name && orderItem?.ticket_event?.location_name?.trim()">
                          <small>{{orderItem?.ticket_event?.location_name}}</small>
                        </div>
                        <div *ngIf="getEventAddress(orderItem?.ticket_event)">
                          <small>{{getEventAddress(orderItem?.ticket_event)}}</small>
                        </div>


                      </td>
                      <td>{{orderItem.quantity}}</td>
                      <td>{{getPrice(orderItem) | currency:'USD'}}</td>
                      <td>{{(getPrice(orderItem) * orderItem.quantity )| currency:'USD'}}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th colspan="4" class="text-end">Total</th>
                      <th>{{orderDetails?.order?.total_amount | currency:'USD'}}</th>
                    </tr>
                  </thead>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
