<!-- payment section -->
<div class="container-fluid min-vh-100 dec-value-card bg-new">
  <div class="card-details-main min-vh-100 py-4">
    <div class="card-details-container">
      <div class="row justify-content-between">
        <div class=" align-self-center col-8 col-lg-8 col-md-8 col-sm-7 top-header ">
          <div class="org-name">{{this.organizationName}}</div>

        </div>
        <div class="align-self-center col-3 col-lg-2 col-md-2 col-sm-2 col-xl-2 px-3 pb-1" *ngIf="small_logo_path">
          <img class="img-fluid" [src]="small_logo_path">
        </div>
      </div>
      <div class="panel-body textsize">
        <form name="form" [formGroup]="rafflePaymentForm" (ngSubmit)="onSubmit()">

          <div>

            <div class="row mb-1 mb-lg-2 mb-md-2 mb-sm-2">
              <!--<div class="col-10">
              </div>
              <div class="col-2">
                <img src="{{small_logo_path}}" alt="">
              </div>-->

              <div class="">
                <div class="mt-3 progress-bar-new"></div>
                <div class="sub-info-label"><strong> Order Details </strong></div>
                <div class="my-2 info-data-card">
                  We will send you your raffle tickets to 
                  <strong>{{rafflePaymentForm.value.card.email}}</strong> shortly.
                </div>
                <div class="boldInfo"><strong>Order ID : </strong> {{orderDetails?.order?.ticket_order_id}}</div>
              </div>


            </div>
            <!-- <hr> -->
            <div class="mt-3 progress-bar-new"></div>
            <div>
              <div class="row mt-2 mb-1 mb-lg-2 mb-md-2 mb-sm-2"
                   *ngIf="rafflePaymentForm.value.payer_account_id && this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0">

                <div class="form-group col-md-6">
                  <div class="form-check">

                    <ejs-radiobutton label=" Select Existing Payment Source" name="existing_payment_source" [value]="true" class="form-check-input form-check-label" id="selectExisting"
                                     formControlName="existing_payment_source" (change)="selectExistingPaymentChange()"></ejs-radiobutton>
                    <!--<input type="radio" id="selectExisting" class="form-check-input" [value]="true"
                           name="existing_payment_source" formControlName="existing_payment_source"
                           (change)="selectExistingPaymentChange()" />
                    <label class="form-check-label" for="selectExisting">
                      Select Existing Payment Source
                    </label>-->
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <div class="form-check">
                    <!--<input type="radio" id="selectNew" class="form-check-input" [value]="false"
                    name="existing_payment_source" formControlName="existing_payment_source"
                    (change)="selectExistingPaymentChange()" />-->
                    <ejs-radiobutton label="Create New Payment Source" name="existing_payment_source" [value]="false" class="form-check-input"
                                     id="selectNew" formControlName="existing_payment_source"
                                     (change)="selectExistingPaymentChange()"></ejs-radiobutton>
                    <!--<label class="form-check-label" for="selectNew">
                      Create New Payment Source
                    </label>-->
                  </div>
                </div>
              </div>
              <div class="row mb-1 mb-lg-2 mb-md-2 mb-sm-2"
                   *ngIf="rafflePaymentForm.value.existing_payment_source && this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0">
                <div class="form-group col-sm-12">
                  <select class="form-select " formControlName="payment_source_id"
                          [ngClass]="{'is-invalid': a['payment_source_id'].touched && a['payment_source_id'].errors, 'is-valid': a['payment_source_id'].valid}">
                    <option value="">Select Payment Source</option>
                    <option *ngFor="let card of paymentSourceData?.charge_cards" [value]="card.card_id">
                      {{card.brand_of_card}} - {{card.last_four}}
                    </option>
                  </select>

                  <div *ngIf="a['payment_source_id'].invalid &&
                           (a['payment_source_id'].touched || a['payment_source_id'].dirty )">
                    <small class="text-danger" *ngIf="a['payment_source_id'].errors['required']">
                      Please select payment source
                    </small>

                  </div>
                </div>

              </div>

            </div>
            <div formGroupName="card" *ngIf="!rafflePaymentForm.value.existing_payment_source">
              <!-- name -->
              <div class="row mb-1 mb-lg-2 mb-md-2 mb-sm-2">
                <div class="form-group col-12">
                  <div class="my-1"><strong>Card Details</strong></div>
                  <ejs-textbox class="" id="name_on_card" name="name_on_card" placeholder="Name on Card"  floatLabelType="Never"
                               aria-errormessage="Name is required" formControlName="name_on_card" required></ejs-textbox>
                  <span class="e-error" *ngIf="(f['name_on_card'].touched ||  f['name_on_card'].dirty ) && f['name_on_card'].errors">
                    Name is required
                  </span>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-12 my-1 my-lg-2 my-md-2 my-sm-2">
                  <div id="card-number-element" style="border-radius: 0%;">
                  </div>

                  <div *ngIf="stripeCardNumberErrorMessage" class="">
                    <small class="text-danger">
                      {{stripeCardNumberErrorMessage}}
                    </small>
                  </div>
                </div>
                <div class="col-4  my-1 my-lg-2 my-md-2 my-sm-2">
                  <div id="card-exp-element" style="border-radius: 0%;"></div>

                  <div *ngIf="stripeExpiryErrorMessage" class="">
                    <small class="text-danger">
                      {{stripeExpiryErrorMessage}}
                    </small>
                  </div>
                </div>
                <div class="col-4  my-1 my-lg-2 my-md-2 my-sm-2">
                  <div id="card-cvc-element" style="border-radius: 0%;"></div>
                  <div *ngIf="stripeCVCErrorMessage" class="">
                    <small class="text-danger">
                      {{stripeCVCErrorMessage}}
                    </small>
                  </div>
                </div>
                <div class="col-4  my-1 my-lg-2 my-md-2 my-sm-2">
                  <input type="text" class="form-control" style="border-radius: 0%;" name="zip" placeholder="Zip" formControlName="zip"
                         [ngClass]="{'is-invalid': f['zip'].errors, 'is-valid': f['zip'].touched && f['zip'].valid}" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12  my-1 my-lg-2 my-md-2 my-sm-2 d-flex gap-1 align-items-center"
                   *ngIf="!rafflePaymentForm.value.existing_payment_source && rafflePaymentForm.value.payer_account_id">
                <div class="form-check">
                  <!-- <input class="form-check-input" type="checkbox" name="future_use" placeholder=""
                    formControlName="future_use" id="future_use">
                  <label class="form-check-label" for="future_use" style="font-size:11px;">
                    Save this payment method for future usage
                  </label> -->

                  <ejs-checkbox class="form-check-input" for="future_use" style="font-size:11px;"
                                label="Save this payment method for future usage" cssClass="e-small" name="future_use" formControlName="future_use"
                                id="future_use"></ejs-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- Amount input -->
          <div class="form-group resp mb-0 mb-lg-4 mb-xl-4 mb-sm-4 mb-md-4">
            <div class="row align-items-center">
              <div class="col-12  my-1 my-lg-1 my-md-2 my-sm-2 d-flex gap-1 align-items-center">
                <div class="form-check">
                  <ejs-checkbox class="form-check-input" name="allow_payer_cover_amount"
                                formControlName="allow_payer_cover_amount" id="flexCheckDefault"
                                label="Increase my gift by ${{(this.giftFeesAmt() | number: '1.2-2') }} to offset credit card processing fees."
                                cssClass="e-small" for="allow_payer_cover_amount" style="font-size:11px;"></ejs-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- calculations -->
          <div>
            <div class="progress-bar-new"></div>

            <div class="d-flex justify-content-center textsize">
              <strong class="new-text">Your Transaction Details</strong>
            </div>
            <div class="row justify-content-center p-1 textsize">
              <div class="col-5">
                Order Amount
              </div>
              <div class="col-3 px-5 textsize" *ngIf="this.rafflePaymentForm.value.amount > 0 ; else elseBlock">
                ${{this.rafflePaymentForm.value.amount | number: '1.2-2' }}
              </div>
              <ng-template #elseBlock>
                ${{0 | number: '1.2-2' }}
              </ng-template>
            </div>
            <!-- Gift fees start -->
            <div class="row justify-content-center p-1 textsize"
                 *ngIf="this.rafflePaymentForm.value.allow_payer_cover_amount">
              <div class="col-5">
                Credit Card Processing Fees
              </div>
              <div class="col-3 px-5 textsize">
                ${{this.calculateGiftFees() | number: '1.2-2'}}
              </div>
            </div>

            <div class="row justify-content-center p-1 textsize">
              <div class="col-5">
                Total
              </div>
              <div class="col-3 px-5 textsize">
                ${{this.calculateTotalAmt() | number: '1.2-2'}}
              </div>
            </div>
            <!-- end -->

          </div>
          <div class="d-flex mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3 mt-0">
            <button class="btn pay-now " type="submit" [disabled]="!this.rafflePaymentForm.valid">
              Give Now
            </button>
          </div>
          <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
        </form>
      </div>
    </div>
  </div>
</div>
